import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { QueryBuilderService } from 'app/query/query-builder/query-builder.service';
import { DatatypesService } from 'app/services/datatypes.service';
import { ConditionService } from 'app/query/query-builder/condition.service';
import { Utils } from 'app/services/util';

@Component({
    selector: 'condition-parameter',
    templateUrl: './condition-parameter.component.html',
    styleUrls: ['./condition-parameter.component.scss']
})
export class ConditionParameterComponent implements OnChanges {

    @Input() selectedObject;
    @Output() isDirty: EventEmitter<any> = new EventEmitter();

    operators: any[];
    dataType: string;

    constructor(
        private queryBuilderSvc: QueryBuilderService,
        private datatypesSvc: DatatypesService,
        private conditionSvc: ConditionService,
        private utils: Utils
    ) { }

    ngOnChanges(changes) {

        if (changes.selectedObject.currentValue !== changes.selectedObject.previousValue)
            this.selectedObjectChanged(changes.selectedObject.currentValue);
    }


    selectedObjectChanged(value) {

        this.dataType = this.datatypesSvc.getUserFriendlyTypeName(value.expression.dataType);

        this.operators = this.conditionSvc.getValueOperators(this.dataType, true);

        if (!this.operators.some(f => { return f.id == value.$$operatorValue }))
            value.$$operatorValue = this.operators[0].id;

        this.valuesToArguments();
    }

    valuesToArguments() {

        this.selectedObject.arguments = [];

        if (this.utils.isArray(this.selectedObject.$$values))
            this.selectedObject.$$values.forEach(el => {
                this.selectedObject.arguments.push(this.queryBuilderSvc.createExpressionParameter(el));
            });

        this.selectedObject.operator = this.selectedObject.$$operatorValue;

        this.isDirty.emit();
    }

    setOperator(item) {

        this.selectedObject.$$operatorValue = item.id;
        this.selectedObject.operator = this.selectedObject.$$operatorValue;

        if (this.notBetween())
            if (this.selectedObject.$$values && this.selectedObject.$$values.length) {
                var first = this.selectedObject.$$values.shift();
                this.selectedObject.$$values = [];
                this.selectedObject.$$values.push(first);
            }
    }

    notBetween() {
        if (this.selectedObject.operator != null)
            return !(this.selectedObject.$$operatorValue === 'Between' || this.selectedObject.$$operatorValue === 'NotBetween');
    }

}
