import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { CallbackComponent } from './auth/callback';
import { LoginComponent } from './auth/login';
import { LogoutComponent } from './auth/logout';
import { AuthGuard } from "./auth/guard";

import { AuthModule } from './auth/auth.module';
import { AppComponent } from './app.component';
import { RoutingModule } from './app.routing';

import { StartupComponent } from './startup/startup.component';
import { GalleryComponent } from './gallery/gallery.component';
import { GalleryService } from './gallery/gallery.service';

import { KeysPipe } from './services/keys.pipe';
import { FilterPipe } from './services/filter.pipe';
import { OrderPipe } from './services/orderBy.pipe';
import { ConnectionTypePipe } from './services/connectionType.pipe';
import { DatatypesService } from './services/datatypes.service';
import { Utils } from './services/util';
import { AlertService, ConfirmComponent } from './services/alert.service';

import { QueryComponent } from './query/query.component';
import { QueryService } from './query/query.service';
import { QueryBuilderComponent } from './query/query-builder/query-builder.component';
import { QueryBuilderService } from './query/query-builder/query-builder.service';
import { ConditionService } from './query/query-builder/condition.service';
import { QueryBuilderFieldDetailsComponent } from './query/query-builder/query-builder-field-details.component';

import { ValueSelectorComponent } from './components/value-selector/value-selector.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { OrderbyComponent, OrderbyItemComponent } from './components/orderby/orderby.component';
import { FilterItemService} from './components/filter-item/filter-item.service';
import { FilterItemComponent } from './components/filter-item/filter-item.component';
import { FilterGroupComponent } from './components/filter-item/filter-group/filter-group.component';
import { FilterConditionComponent } from './components/filter-item/filter-condition/filter-condition.component';

import { ValidateDirective } from './directives/validate.directive';
import { AceEditorDirective } from './directives/ace-editor/ace-editor.directive';
import { ContextualMenuDirective } from './directives/contextual-menu/contextual-menu.directive';
import { DropdownDirective } from './directives/dropdown/dropdown.directive';
import { SpinnerDirective } from './directives/spinner/spinner.directive';
import { ToggleDirective } from './directives/toggle/toggle.directive';
import { OnCreateDirective } from './directives/onCreate.directive';
import { CheckBoxDirective } from './directives/checkbox/checkbox.directive';
import { StopPropagationDirective } from './directives/stop-propagation.directive';

import { ErrorResponseInterceptor, AuthInterceptor, APIInterceptor, DateConverterInterceptor } from './interceptors';
import { AppService } from './app.service';
import { RefreshComponent } from './refresh/refresh.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ConditionListComponent } from './components/filter-item/filter-condition/condition-details/condition-list/condition-list.component';
import { ConditionParameterComponent } from './components/filter-item/filter-condition/condition-details/condition-parameter/condition-parameter.component';
import { ConditionRelativeComponent } from './components/filter-item/filter-condition/condition-details/condition-relative/condition-relative.component';
import { ConditionValueComponent } from './components/filter-item/filter-condition/condition-details/condition-value/condition-value.component';
import { ShowInputErrorsComponent } from './components/show-input-errors/show-input-errors.component';
import { UtcDate, DateOnly } from './services/utcDate.pipe';
import { AuthService } from './auth/auth.service';
import { GlobalErrorHandler } from './services/globalError.service';


@NgModule({
  declarations: [
    AppComponent,
    StartupComponent,
    GalleryComponent,
    ValueSelectorComponent,
    ValidateDirective,
    DatePickerComponent,
    QueryComponent,
    RefreshComponent,
    QueryBuilderComponent,
    QueryBuilderFieldDetailsComponent,
    AceEditorDirective,
    ContextualMenuDirective,
    DropdownDirective,
    CheckBoxDirective,
    StopPropagationDirective,
    KeysPipe,
    FilterPipe,
    SpinnerDirective,
    ToggleDirective,
    CallbackComponent,
    LoginComponent,
    LogoutComponent,
    OrderbyComponent,
    OrderbyItemComponent,
    FilterItemComponent,
    FilterGroupComponent,
    FilterConditionComponent,
    ConditionListComponent,
    ConditionParameterComponent,
    ConditionRelativeComponent,
    ConditionValueComponent,
    ConfirmComponent,
    ShowInputErrorsComponent,
    OrderPipe,
    UtcDate,
    DateOnly,
    OnCreateDirective,
    ConnectionTypePipe,
    AutofocusDirective,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RoutingModule,
    AuthModule.forRoot(),
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgSelectModule,
    BrowserAnimationsModule,
  ],
  providers: [
    GalleryService,
    AuthGuard,
    AuthService,
    QueryService,
    QueryBuilderService,
    ConditionService,
    DatatypesService,
    FilterItemService,
    Utils,
    AlertService,
    AppService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorResponseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateConverterInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
  },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
