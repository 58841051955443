<fieldset [ngSwitch]="true" ngModelGroup="_{{name}}" #form="ngModelGroup">

  <ng-container *ngSwitchCase="editor === 'Boolean'">
    <ng-select [ngModel]="model" (ngModelChange)="modelChange($event)" [clearable]="false" [items]="items" name="value"></ng-select>
  </ng-container>

  <ng-container *ngSwitchCase="editor === 'Select'">
    <select [ngModel]="model" (ngModelChange)="modelChange($event)" name="value">
      <option *ngFor="let val of values" [value]="val">{{val}}</option>
  </select>
  </ng-container>

  <ng-container *ngSwitchCase="editor === 'Integer' || editor === 'Number' || editor === 'GUID'">

      <input name="value" type="text" [ngModel]="model" (ngModelChange)="modelChange($event)" [validate]="validators" class="ms-TextField-field" autocomplete="off"/>
  </ng-container>

  <div *ngSwitchCase="editor === 'DateTime' || editor === 'DateTimeOffset' || editor === 'Date'" class="date-editor">

    <date-picker name="value" [ngModel]="model" (ngModelChange)="modelChange($event)" [validate]="validators" [dateOnly]="dateOnly"></date-picker>
    
  </div>

  <ng-container *ngSwitchCase="editor === 'None'">

  </ng-container>

  <ng-container *ngSwitchDefault>
    <input class="ms-TextField-field" name="value" type="text" [ngModel]="model" (ngModelChange)="modelChange($event)" [validate]="validators" />
  </ng-container>

  <ng-container *ngIf="form?.control?.controls?.value">
      <show-input-errors [control]="form.control.controls.value" [submitted]="showErrors" [params]="{dateOnly:dateOnly}"></show-input-errors>
  </ng-container>
</fieldset>

<!-- <fieldset ngModelGroup="_{{name}}" #form="ngModelGroup" [ngSwitch]="editor">

  <div *ngSwitchCase="'Boolean'">
    <div class="ms-Dropdown" tabindex="0">
        <ng-select [ngModel]="model" (ngModelChange)="modelChange($event)" [clearable]="false" [items]="items"></ng-select>
    </div>
  </div>

  <div *ngSwitchCase="'Select'">
    <select [ngModel]="model" (ngModelChange)="modelChange($event)" name="value">
      <option *ngFor="let val of values" [value]="val">{{val}}</option>
  </select>
  </div>

  <div *ngSwitchCase="'Integer'">
    <input name="value" type="text" [ngModel]="model" (ngModelChange)="modelChange($event)" [validate]="validators"  #integerValue="ngModel" class="ms-TextField-field"
      [ngClass]="{ 'ms-TextField-invalid': !integerValue.valid && !integerValue.pristine }" />

    <small *ngIf="!integerValue.valid && integerValue.hasError('required') && !integerValue.pristine" class="ms-TextField-errorMessage">
      Value is required.
    </small>

    <small *ngIf="!integerValue.valid && integerValue.hasError('float') && !integerValue.pristine" class="ms-TextField-errorMessage">
       Invalid integer.
    </small>

  </div>

  <div *ngSwitchCase="'Number'">
    <input name="value" type="text" [ngModel]="model" (ngModelChange)="modelChange($event)" [validate]="validators" #floatValue="ngModel" class="ms-TextField-field"
      [ngClass]="{ 'ms-TextField-invalid': !floatValue.valid && !floatValue.pristine }" />

    <small *ngIf="!floatValue.valid && floatValue.hasError('required') && !floatValue.pristine" class="ms-TextField-errorMessage">
      Value is required.
    </small>

    <small *ngIf="!floatValue.valid && floatValue.hasError('float')" class="ms-TextField-errorMessage">
       Invalid number.
    </small>
  </div>

  <div *ngSwitchCase="'Date'">
    <date-picker name="value" [ngModel]="model" (ngModelChange)="modelChange($event)" [validate]="validators" dateOnly="true" #dateValue="ngModel"></date-picker>
    
    <small *ngIf="!dateValue.valid && dateValue.hasError('date')" class="ms-TextField-errorMessage">   
      Invalid date. The correct format is  MM/dd/yyyy
    </small>
  </div>

  <div *ngSwitchCase="'DateTime'">
    <date-picker name="value" [ngModel]="model" (ngModelChange)="modelChange($event)"  [validate]="validators" #dateTimeValue="ngModel"></date-picker>
    
    <small *ngIf="!dateTimeValue.valid && dateTimeValue.hasError('date')" class="ms-TextField-errorMessage">   
      Invalid date. The correct format is  MM/dd/yyyy HH:mm
    </small>
  </div>

  <div *ngSwitchCase="'DateTimeOffset'">
    <date-picker name="value" [ngModel]="model" (ngModelChange)="modelChange($event)" [validate]="validators" #dateTimeOffsetValue="ngModel"></date-picker>
    
    <small *ngIf="!dateTimeOffsetValue.valid && dateTimeOffsetValue.hasError('date')" class="ms-TextField-errorMessage">   
      Invalid date. The correct format is  MM/dd/yyyy HH:mm
    </small>
  </div>

  <div *ngSwitchCase="'GUID'">
    <input name="value" type="text" [ngModel]="model" (ngModelChange)="modelChange($event)" [validate]="validators" #guidValue="ngModel" class="ms-TextField-field"
      [ngClass]="{ 'ms-TextField-invalid': !guidValue.valid }" />

    <small *ngIf="!guidValue.valid && guidValue.hasError('required')" class="ms-TextField-errorMessage">
      Value is required.
    </small>

    <small *ngIf="!guidValue.valid && guidValue.hasError('guid')" class="ms-TextField-errorMessage">   
      Invalid GUID.The correct format is "{{'{ '}}"xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx."{{' }'}}"
    </small>
  </div>

  <div *ngSwitchCase="'None'">
    <input class="ms-TextField-field" name="value" type="text" [ngModel]="model" (ngModelChange)="modelChange($event)" [validate]="validators" />
  </div>

  <div *ngSwitchDefault>
    <input class="ms-TextField-field" name="value" type="text" [ngModel]="model" (ngModelChange)="modelChange($event)" [validate]="validators" />
  </div>

</fieldset> -->