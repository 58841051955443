<div class="condition">

    <div class="ms-Grid" dir="ltr">
        <div class="ms-Grid-row">
            <div class="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                <div *ngIf="tableRefs.length" class="ms-Dropdown object">
                    <ng-select [clearable]="false" [(ngModel)]="tableRef" (ngModelChange)="changeTableRef()"
                        [items]="tableRefs" bindLabel="name" appendTo="body">
                        <ng-template ng-label-tmp let-item="item">
                            <span class="text">{{item.name}}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            <span title="{{item.name}}">{{item.name}}</span>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div *ngIf="!tableRef?.columns" class="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                <div class="spinner-wrapper"><div class="ms-Spinner" fabricSpinner></div></div>
            </div>
            <ng-container *ngIf="tableRef?.columns">
                <div class="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                    <div class="ms-Dropdown columns">
                        <ng-select [(ngModel)]="item.expression.column" (ngModelChange)="changeColumn()"
                            [items]="tableRef.columns | orderBy: 'name'" [clearable]="false" bindValue="name"
                            bindLabel="name" appendTo="body">
                            <ng-template ng-label-tmp let-item="item">
                                <span class="text">{{item.name}}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <span title="{{item.name}}">{{item.name}}</span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>


                <div class="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                    <div *ngIf="editorsList" class="ms-Dropdown editor">
                        <ng-select [(ngModel)]="editor" [items]="editorsList" [clearable]="false" appendTo="body">
                        </ng-select>
                    </div>
                </div>
                <ng-container [ngSwitch]="editor">
                    <ng-container *ngSwitchCase="'Relative'">
                        <condition-relative [selectedObject]="item" (isDirty)="isDirty($event)"></condition-relative>
                    </ng-container>

                    <ng-container *ngSwitchCase="'List'">
                        <condition-list [selectedObject]="item" [sources]="tableRefs" [connection]="connection" [workspaceId]="workspaceId"
                            (isDirty)="isDirty($event)"></condition-list>
                    </ng-container>

                    <ng-container *ngSwitchCase="'Value'">
                        <condition-value [selectedObject]="item" (isDirty)="isDirty($event)" [index]="index">
                        </condition-value>
                    </ng-container>

                    <ng-container *ngSwitchCase="'Parameter'">
                        <condition-parameter [selectedObject]="item" (isDirty)="isDirty($event)"></condition-parameter>
                    </ng-container>
                </ng-container>
            </ng-container>
            <!-- 
                    <div class="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                        <div *ngIf="operators && showOperatorsList" class="ms-Dropdown operator">
                            <ng-select [(ngModel)]="item.operator" (ngModelChange)="changeOperator()" [items]="operators" 
                            [clearable]="false" bindValue="id" bindLabel="name" appendTo="body"></ng-select>
                        </div>
                    </div>
                    <div class="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                    <ng-container *ngIf="item.arguments">
                        <div [ngSwitch]="editor">
                            <ng-container *ngSwitchCase="'relative'">
                                <div class="ms-Dropdown arguments">
                                    <ng-select [(ngModel)]="item.arguments[0].name" [items]="relativeList" 
                                    [clearable]="false" bindValue="id" bindLabel="name" appendTo="body"></ng-select>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="'value'">
                                <div *ngIf="!isBetween()">   
                                    <value-selector [(ngModel)]="item.arguments[0].value" [name]="index" [datatype]="item.expression.dataType" required="required" *ngIf="needEditor()"></value-selector>
                                </div>
                                <div *ngIf="isBetween()" class="between">
                                    <value-selector [(ngModel)]="item.arguments[0].value"  name="{{index+'_0'}}" [datatype]="item.expression.dataType" required="required"></value-selector>
                                    <value-selector [(ngModel)]="item.arguments[1].value"  name="{{index+'_1'}}" [datatype]="item.expression.dataType" required="required"></value-selector>
                                </div>
                            </ng-container>
                            <div *ngSwitchCase="'parameter'" class="form-parameters">
                                <div *ngIf="!isBetween()">
                                    <input name="parameter" type="text" [(ngModel)]="item.arguments[0].name" autocomplete="off" *ngIf="needEditor()"/>
                                </div>
                                <div *ngIf="isBetween()" class="between">
                                    <input name="parameter" type="text" [(ngModel)]="item.arguments[0].name" autocomplete="off" />
                                    <input name="parameter" type="text" [(ngModel)]="item.arguments[1].name" autocomplete="off" />
                                </div>
                            </div>
                            <ng-container *ngSwitchCase="'list'">
                                <div (onCreate)="initValuesList()" class="ms-Dropdown arguments">
                                    <div class="spinner-wrapper" *ngIf="loading"><div class="ms-Spinner" fabricSpinner></div></div>
                                    <ng-select
                                        multiple="true"
                                        class="values-list"
                                        [clearable]="false"
                                        hideSelected="true"
                                        bindLabel="value"
                                        [(ngModel)]="item.arguments"
                                        [items]="valuesList"
                                        *ngIf="valuesList.length > 0"
                                        (scrollToEnd)="loadMore()"
                                        [loading]="loadingMore"
                                        [dropdownPosition]="'bottom'"
                                    >
                                        <ng-template ng-loadingspinner-tmp>
                                            <div class="idle ms-Spinner" fabricSpinner></div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div> -->
            <div class="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                <button (click)="remove()" title="Remove Filter" aria-label="Remove Filter"
                    class="ms-Button ms-CommandBarItem-link remove">
                    <span class="flex-container">
                        <i class="ms-Icon ms-Icon--Delete"></i>
                    </span>
                </button>
            </div>

        </div>
    </div>





</div>