import { Component, OnInit, OnChanges, SimpleChanges, Input, forwardRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgForm, ControlContainer, FormGroup } from '@angular/forms';

import { DatatypesService } from '../../services/datatypes.service';
import { Utils } from 'app/services/util';

import * as dayjs from 'dayjs';
import BigNumber from 'bignumber.js';

export const VALUE_SELECTOR_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ValueSelectorComponent),
  multi: true
};

export const VALUE_SELECTOR_CONTROL_CONTAINER: any = {
  provide: ControlContainer,
  useExisting: NgForm
};

const noop = () => {
};

@Component({
  selector: 'value-selector',
  templateUrl: './value-selector.component.html',
  styleUrls: ['./value-selector.component.scss'],
  providers: [VALUE_SELECTOR_CONTROL_VALUE_ACCESSOR],
  viewProviders: [VALUE_SELECTOR_CONTROL_CONTAINER]
})
export class ValueSelectorComponent implements OnChanges, ControlValueAccessor {

  @ViewChild('form', { static: true }) form: FormGroup;

  @Input() dataType;
  @Input() list: Array<any>;
  @Input() required: boolean;
  @Input() showErrors: boolean;
  @Input() showVariables: boolean;
  @Input() showLastRunVariable: boolean;
  @Input() name: string = 'form';
  @Input() minDate;

  model;
  validators: Array<any>;
  editor: string;
  dateOnly: boolean;

  items =['True', 'False' ];

  constructor(
    public datatypesSvc: DatatypesService,
    private utils: Utils) {
  }

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  //$formatters - model => view
  writeValue(value: any) {

    if (value !== this.model) {
      this.model = this.formatter(value);
    }
  }

  //$parsers - view => model
  modelChange(value) {

    this.model = value;

    setTimeout(() => {
      if (!this.form.invalid) {
        this.onChangeCallback(this.parsers(value));
      } else {
        this.onChangeCallback(null);
      }
    });

  }


  ngOnChanges(changes: SimpleChanges) {

    if (changes.dataType && changes.dataType.currentValue) {

      this.validators = [];

      if (this.required) {
        this.validators.push('required');
      }

      if (this.list) {
        this.editor = "Select";
      }
      else {

        this.editor = this.datatypesSvc.getUserFriendlyTypeName(this.dataType);

        switch (this.editor) {
          case 'Money':
          case 'Number':
            this.validators.push('float');
            break;
          case 'Integer':
            this.validators.push('integer');
            break;
          case 'GUID':
            this.validators.push('guid');
            break;
          case 'DateTime':
          case 'DateTimeOffset':
            this.validators.push('date');
            this.dateOnly = false;
            break;
          case 'Date':
            this.validators.push('date');
            this.dateOnly = true;
            break;
        }
      }
    }
  }

  parsers(value) {

    let float = (value) => {

        if (!value) return;

        if (this.utils.options.decimalSeparator === '.')
            value = parseFloat(value);
        else {

            if (typeof value == 'number')
                value = value.toString();

            value = parseFloat(value.replace(this.utils.options.decimalSeparator, '.'));
        }

        return value;
    };

    let integer = (value) => {

      if (!value) return;

      if (Number.isSafeInteger(parseFloat(value)))
          value = parseFloat(value);
      else
          value = new BigNumber(value); 

      return value;
    };

    let date = (value) => {

        if (this.utils.isString(value)) {
            let m;
            if (this.dateOnly)
                m = dayjs(value, this.utils.options.dateFormat, true);
            else {
                m = dayjs(value, this.utils.options.dateTimeFormat, true);
                if (!m.isValid())
                    m = dayjs(value, this.utils.options.dateTimeSecondsFormat, true);
            }

            if (m.isValid()) {
                return m.toDate();
            }
        }
        else if (this.utils.isDate(value)) {
            return value;
        }

    };

    switch (this.editor) {
        case 'Money':
        case 'Number':
            value = float(value);
            break;
        case 'Integer':
            value = integer(value);
            break;
        case 'DateTime':
        case 'DateTimeOffset':
        case 'Date':
            value = date(value);
            break;

    }

    return value;
}


formatter(value) {

    if (typeof value === 'number') {
        if (this.utils.options.decimalSeparator === '.')
            return value.toString();
        else
            return value.toString().replace('.', this.utils.options.decimalSeparator);
    } else if (BigNumber.isBigNumber(value)) {
        return value.toString();
    }
    else if (this.utils.isDate(value)) {
        let m = dayjs(value);
        if (this.dateOnly)
            return m.format(this.utils.options.dateFormat);
        else
            if (m.second())
                return m.format(this.utils.options.dateTimeSecondsFormat);
            else
                return m.format(this.utils.options.dateTimeFormat);
    }
    else
        return value;
}

}
