import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatatypesService } from 'app/services/datatypes.service';
import { QueryBuilderService } from './query-builder.service';
import { Utils } from 'app/services/util';

@Component({
    selector: 'query-builder-field-details',
    template: `
      
            <li class="ms-ContextualMenu-item">
                <a class="ms-ContextualMenu-link" [ngClass]="{'is-selected': selectedObject.expression.type  == 'Column'}" (click)="setDefaultValue()">
                    <span>Value</span>
                </a>
            </li>       
            <ng-container *ngIf="aggregations.length">          
                <li *ngFor="let option of aggregations" class="ms-ContextualMenu-item">
                    <a class="ms-ContextualMenu-link" [ngClass]="{'is-selected': option.name == selectedObject.expression.name}" (click)="setValue(option)">
                        <span>{{option.name}}</span>
                    </a>
                </li>           
            </ng-container>
            <ng-container *ngIf="expressions.length > 0 ">          
                <li *ngFor="let option of expressions" class="ms-ContextualMenu-item">
                    <a class="ms-ContextualMenu-link" [ngClass]="{'is-selected': option.name == selectedObject.expression.name}" (click)="setValue(option)">
                        <span>{{option.title}}</span>
                    </a>
                </li>
            </ng-container>
        
    `,
    styles: [``]
})
export class QueryBuilderFieldDetailsComponent implements OnInit {

    @Input() selectedObject;

    @Input() columns;
    @Output() isDirty = new EventEmitter<any>();

    dataType;
    aggregations;
    expressions;

    constructor(
        private datatypesService: DatatypesService,
        private queryBuilderService: QueryBuilderService,
        private utils: Utils) {

    }

    ngOnInit() {

        const item = this.queryBuilderService.getSourse(this.selectedObject.expression);
        this.dataType = this.datatypesService.getUserFriendlyTypeName(item.dataType);
        this.aggregations = this.getAggregations(this.dataType);
        this.expressions = this.getExpressions(this.dataType);
    }

    setDefaultValue() {

        const item = this.queryBuilderService.getSourse(this.selectedObject.expression);
        this.selectedObject.expression = this.queryBuilderService.createExpressionColumn(item.source, item.column, item.dataType);
        this.selectedObject.label = null;
    }

    setValue(value) {

        const item = this.queryBuilderService.getSourse(this.selectedObject.expression);
        this.selectedObject.expression = this.queryBuilderService.createExpressionFunction(value.name, item.source, item.column, item.dataType);

        this.isDirty.emit();

        const list = this.columns
            .filter((f) => {
                return !(this.utils.isUndefined(f.label) || f.label === null) && f.label.substring(0, 4) == "expr" && !isNaN(parseInt(f.label.substring(4)));
            }).map((m, key) => {
                return { label: m.label, index: parseInt(m.label.substring(4)) };
            });
        if (this.utils.isUndefined(this.selectedObject.label) || this.selectedObject.label === null)
            this.selectedObject.label = "expr" + this.createIndex(list);
    }


    getAggregations(dataType) {

        let aggregations = [];
        if (dataType == 'Date' || dataType == 'DateTime' || dataType == 'Text' || dataType == 'GUID' || dataType == 'Boolean' || dataType == 'Bytes')
            aggregations = [
                { name: "Count" }
            ];
        if (dataType == 'Number' || dataType == 'Integer')
            aggregations = [
                { name: "Avg" },
                { name: "Count" },
                { name: "Max" },
                { name: "Min" },
                { name: "Sum" }
            ];
        return aggregations;
    }

    getExpressions(dataType) {

        let expressions = [];
        if (dataType == 'DateTime' || dataType == 'Date')
            expressions = [
                { name: "Day", title: "Day" },
                { name: "DayNumber", title: "Day of Month" },
                { name: "Month", title: "Month" },
                { name: "MonthNumber", title: "Month of Year" },
                { name: "Quarter", title: "Quarter" },
                { name: "QuarterNumber", title: "Quarter of Year" },
                { name: "Year", title: "Year" },
            ];
        return expressions;
    }

    createIndex(list) {

        const sortBubble = (data) => {
            let tmp;
            for (let i = data.length - 1; i > 0; i--) {
                for (let j = 0; j < i; j++) {
                    if (data[j].index > data[j + 1].index) {
                        tmp = data[j].index;
                        data[j].index = data[j + 1].index;
                        data[j + 1].index = tmp;
                    }
                }
            }
            return data;
        }

        const searchMissingIndex = (list) => {
            let i = -1, need, got, missing = [];
            while (++i < list.length) {
                need = i + 1 + missing.length;
                got = list[i].index;
                while (need != got-- && missing.push(got))
                    ;
            }
            return missing.sort();
        }

        let indexArr = searchMissingIndex(sortBubble(list));
        return indexArr.length ? indexArr[0] : list.length + 1;
    }
}
