import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpParams } from '@angular/common/http';
import {throwError, empty as observableEmpty,  Observable } from 'rxjs';
import {map, expand, reduce, delay, catchError} from 'rxjs/operators';
import { Utils } from 'app/services/util';

const JsonBigint = require('json-bigint');

interface IQueryResponse {
    result: any[];
    nextPageToken: string;
    rowsAffected: number;
    time: number;
    schema: any;
}

@Injectable()
export class QueryService {


    private stopTick: any;

    private tokens: Array<any> = [];
    private keyTokens: string;

    public loading: boolean

    constructor(private http: HttpClient, private utils: Utils) {  
    }

    
    cancelTick() {
        clearInterval(this.stopTick);
    }

    setKeyTokens(key: string) {
        this.keyTokens = key;
    }

    delTokens(key: string) {
        this.tokens = this.tokens.filter(f => f.keyTokens !== key);
    }


    query(data): Observable<any> {

        let model = this.createRequestContent(data);

        // TODO: query by id

        return this.http.post(`api/${data.workspaceId}/connections/${data.connectionId}/query`, model, { responseType: 'text' })
        .pipe(
            map(content => JsonBigint.parse(content, this.utils.jsonDateParser))
        )
        .pipe(
            map(response=> {

                const result = {
                    result: response[0],
                    nextPageToken: response[1].nextPageToken,
                    time: response[1].time,
                    schema: response[1].schema,
                    rowsAffected: response[1].rowsAffected,
                };

                if (!!result.nextPageToken)
                    this.addToken(result.nextPageToken);

                    return result;
            })
        )
        .pipe(
            expand((response: any) => {
                return (response.nextPageToken ? this.queryMore(data.connectionId, data.workspaceId, response.nextPageToken) : observableEmpty())
            }),
            reduce((response: IQueryResponse, currentObject: IQueryResponse) => {

                response.result.push(...currentObject.result)

                return response;
            }));
    }

    queryMore(id, workspaceId, token: string) {

        return this.http.post(`api/${workspaceId}/connections/${id}/querymore/${encodeURIComponent(token)}`, {}, { responseType: 'text' })
        .pipe(
            map(content => JsonBigint.parse(content, this.utils.jsonDateParser))
        )
        .pipe(
            map(response => {

                const result = {
                    result: response[0],
                    nextPageToken: response[1].nextPageToken,
                    time: response[1].time,
                    rowsAffected: response[1].rowsAffected,
                };
    
                if (!result.nextPageToken) {
                    this.delToken(token);
                }
    
                return result;
            })
        );
    }
     
    queryLongPolling(data): Observable<any> {

        let model = this.createRequestContent(data);

        // TODO: query by id

        return this.http.post(`api/${data.workspaceId}/connections/${data.connectionId}/queryLongPolling`, model, { responseType: 'text' })
        .pipe(
            map(content => JsonBigint.parse(content, this.utils.jsonDateParser))
        )
        .pipe(
            map(response=> {

                const result = {
                    result: response[0],
                    error: response[1].error,
                    nextPageToken: response[1].nextPageToken,
                    time: response[1].time,
                    rowsAffected: response[1].rowsAffected,
                    schema: response[1].schema,
                };

                return result;
            })
        )
        .pipe(
            expand(response => {

                if (response.error) {
                    return throwError(new Error(response.error))
                }

                return (response.nextPageToken ? this.queryMoreLongPolling(data.connectionId, data.workspaceId, !!response?.result?.length, response.nextPageToken) : observableEmpty())
            }),
            reduce((response: IQueryResponse, currentObject: IQueryResponse) => {

                response.result.push(...currentObject.result)

                if(currentObject.schema) {
                    response.schema = currentObject.schema
                }

                return response;
            }));
    }

    queryMoreLongPolling(id, workspaceId, hasResult, token: string) {

        return this.http.get(`api/${workspaceId}/connections/${id}/querymoreLongPolling/${encodeURIComponent(token)}`, { responseType: 'text' })
        .pipe(
            map(content => JsonBigint.parse(content, this.utils.jsonDateParser))
        )
        .pipe(
            delay(hasResult ? 0: 5000),
            map(response => {

                const result = {
                    result: response[0],
                    error: response[1].error,
                    nextPageToken: response[1].nextPageToken,
                    time: response[1].time,
                    rowsAffected: response[1].rowsAffected,
                    ...(response[1].schema && { schema: response[1].schema })
                };
    
                return result;
            })
        );
    }

    refreshConnections(workspaceId): Observable<any> {
        return this.http.get(`api/${workspaceId}/connections/queryable`);
    }

    refreshObjects(id, workspaceId): Observable<any> {
        return this.http.post(`api/${workspaceId}/connections/${id}/objects`, {});
    }

    refreshObject(id, workspaceId, objectName) {
        return this.http.post(`api/${workspaceId}/connections/${id}/object`, { name: objectName, columns: true, parentRelations: true });
    }

    getSQL(connectionId, workspaceId, data): Observable<any> {
        return this.http.post(`api/${workspaceId}/connections/${connectionId}/convert`, { query: JSON.stringify(data, this.replacer) })
    }

    getParameters(connectionId, workspaceId, data) {

        return new Promise((resolve, reject) => {

            this.http.post(`api/${workspaceId}/connections/${connectionId.toString()}/sqlparameters`, data)
                .subscribe((response) => {
                    resolve(response);
                }, (response) => {
                    reject(response);
                });

        });
    }

    save(workspaceId, data) {
        const req = new HttpRequest(data.id ? 'PUT' : 'POST', `api/${workspaceId}/queries/`, data);
        return this.http.request(req);
    }

    getCollections(workspaceId) {
        return this.http.get(`api/${workspaceId}/collections`);
    }

    createRequestContent(query) {

        let toDictionary = function (obj) {
            let parameters = {};
            if (obj) {
                for (let i = 0; i < obj.length; i++) {
                    parameters[obj[i].name] = obj[i].value;
                }
            }
            return parameters;
        }

        return {
            connectionId: query.connectionId,
            queryType: query.contentType,
            query: query.contentType ? query.sql : JsonBigint.stringify(query.model, this.replacer),
            pageSize: query.pageSize || 2000,
            parameters: toDictionary(query.parameters),
            origin: 'Excel'
        };
    }

    queryColumn(id, workspaceId, model) {
        return this.http.post(`api/${workspaceId}/connections/${id}/querycolumn`, model)
        .pipe(
            map(response=> {

                const result = {
                    result: response[0],
                    nextPageToken: response[1].nextPageToken,
                    time: response[1].time,
                    schema: response[1].schema,
                    rowsAffected: response[1].rowsAffected,
                };

                if (!!result.nextPageToken)
                    this.addToken(result.nextPageToken);

                    return result;
            })
        )
    }

    getSubscriptions(workspaceId) {

        return new Promise((resolve, reject) => {
            this.http.get(`api/${workspaceId}/subscriptions`)
                .subscribe((response: any) => {

                    const accountId = response.accountId;
                    const message = `You have reached a limit of queries in a FREE edition.\r\nFor more information, refer to <a target="_blank" href="https://app.skyvia.com/#/accounts/${accountId}/plans/query" style="color:#4285f4">Query Pricing<a>.`
                    return resolve(message);
                },
                (response) => { return reject(response); });
        });
    }

    public startTick() {

        this.stopTick =
            setInterval(() => {
                if (this.tokens.length) {
                    let tokens = this.tokens.map(item => item.token);
                    this.http.post('api/cursors/querytick', tokens).subscribe(_ => { });
                }
            }, 5000, 0, false);
    }

    private addToken(token: string) {

        if (!this.tokens.some(f => f.token === token))
            this.tokens.push({ keyTokens: this.keyTokens, token: token });
    }

    private delToken(token: string) {
        this.tokens = this.tokens.filter(f => f.token !== token);
    }

    private replacer(key, value) {
        return typeof key === 'string' && key.startsWith('$') ? undefined : value;
    } 

}