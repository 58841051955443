import { Component, OnInit, OnDestroy } from '@angular/core';

import { QueryService } from './query.service';
import { QueryBuilderService } from './query-builder/query-builder.service';
import { AlertService } from 'app/services/alert.service';
import { Utils } from 'app/services/util';
import { DatatypesService } from 'app/services/datatypes.service';
import { Toast, MessageBarType } from '../_fabric/Toast';
import { AppService } from 'app/app.service';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { environment } from 'environments/environment';

const JsonBigint = require('json-bigint');

type View = "query" | "parameters" | "save";

@Component({
    selector: 'skyvia-query',
    templateUrl: './query.component.html',
    styleUrls: ['./query.component.scss']
})
export class QueryComponent implements OnInit, OnDestroy {

    host = environment.APP_ENDPOINT;

    connections;
    loadingConnections: boolean;

    accounts;

    parameters: Array<any>;
    datatypes: Record<string, number>;

    view: View;
    saveAsCopy: boolean;
    query: IQuery;

    editingSql: boolean;

    canSave: boolean;
    canQuery: boolean;

    workspaceIdDefault: number;

    editorOptions = {
        showPrintMargin: false,
        wrap: true,
    };
    
    intervalId: NodeJS.Timeout;

    private toast: Toast;

    constructor(
        public queryService: QueryService,
        private queryBuilderService: QueryBuilderService,
        private datatypesService: DatatypesService,
        private alertService: AlertService,
        private utils: Utils,
        private appService: AppService) {

        this.toast = new Toast();

    }

    ngOnInit() {
        this.getWorkspaces();
    }

    ngOnDestroy() {

        this.queryService.cancelTick();
        this.queryService.delTokens('query');

        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    init() {

        this.workspaceIdDefault = this.appService.getWorkspaceId();

        const query = localStorage.getItem('query');
        if (query) {
            this.query = JsonBigint.parse(query, this.utils.jsonDateParser);

            localStorage.removeItem('query');
        } else {

            this.query = {
                contentType: 0,
                parameters: [],
                workspaceId: null,
                connectionId: null,
                sql: '',
                model: this.queryBuilderService.createModel(),
            };
        }

        if (!!this.query.sharingType) {
            this.saveAsCopy = true;
        }

        if (!this.query.workspaceId) { // NOTE: query without workspaceId
            this.query.workspaceId = this.workspaceIdDefault;
        }

        this.refreshConnections();

        if (this.query.connectionId) {
            setTimeout(() => {
                this.onSelectConnection(false);
            }, 500);
        }

        this.parameters = [];
        this.datatypes = this.datatypesService.userFriendlyDatatypes;

        // TODO: use  https://docs.microsoft.com/ru-ru/office/dev/add-ins/develop/parent-to-dialog

        // Office.onReady()
        // .then(()=> {
        //     Office.context.ui.addHandlerAsync(
        //         Office.EventType.DialogParentMessageReceived,
        //         this.onMessageFromParent.bind(this));
        // });

        this.intervalId = setInterval(() => {
            const item = JsonBigint.parse(localStorage.getItem('message'));
            if (item) {
                const { type, data } = item;
                this.update(type, data);
                localStorage.removeItem('message');
            }
        }, 1000);

        this.queryService.startTick();

        this.view = 'query';
    }

    update(type, data) {
        if (type === 'query-executed') {
            this.queryService.loading = false;
            this.toast.showToast(data.type, data.message);
        }
    }

    setView(state) {

        if (this.query.contentType !== state) {
            if (!state) { // out of sql
                if (this.editingSql) {
                    this.alertService.open('Your changes will be lost. Are you sure you want to switch to the Builder tab?')
                        .then(() => {
                            this.query.contentType = state;
                            this.editingSql = false;
                        }, () => { })
                } else {
                    this.query.contentType = state;
                }

            } else { // out of builder

                const validate = this.queryBuilderService.validate(this.query.model);
                if (!!validate && validate.error) {
                    // this.error = validate.message;
                } else {
                    if (this.canQuery) {
                        this.getSQL(this.query.connectionId, this.query.workspaceId, this.query.model);
                    }
                }

                this.query.contentType = state;
            }
            this.query.contentType = state;
        }
    }

    onSelectConnection(force: boolean) {

        this.refreshObjects(this.query.connectionId, this.query.workspaceId);
        this.queryService.setKeyTokens('query');

        if (force) {
            this.queryBuilderService.object = null;
            this.query.model = this.queryBuilderService.createModel();
        }

        this.validate();
    }

    onRefreshConnections() {
        if (this.query.connectionId) {
            this.query.connectionId = null;
        }
        this.validate();
        this.refreshConnections();
    }

    run() {
        this.queryService.loading = true;
        let { id, ...cleanQuery } = this.query;
        Office.context.ui.messageParent(JSON.stringify({ type: 'query-run', query: cleanQuery }))
    }

    save() {

        const data = {
            id: this.saveAsCopy ? null : this.query.id || null,
            name: this.query.name,
            description: this.query.description || null,

            contentType: !!this.query.contentType ? 1 : 0,
            resultType: 'table',
            content: !!this.query.contentType ? this.query.sql : JsonBigint.stringify(this.query.model),
            resultConfig: JSON.stringify({}),

            connectionType: this.query.connectionId ? this.getConnectionById(this.query.connectionId).type : null,
            connectionId: this.query.connectionId ? this.query.connectionId : null,
            connectionName: this.query.connectionId ? this.getConnectionById(this.query.connectionId).name : null,

            collectionId: this.query.collectionId,
        };

        this.saveQuery(this.query.workspaceId, data);
    }

    menuSave() {

        if (!this.query) { return; };

        if (this.query.sharingType || !this.query.id) {
            return false;
        }

        return true;
    }

    cancel() {
        this.view = 'query';
    }

    viewSave() {
        this.view = 'save';
    }

    onChangeSQL(data) {
        this.editingSql = true;
        this.validate();
    }

    validate() {

        setTimeout(() => { // NOTE: workaround ExpressionChangedAfterItHasBeenCheckedError
            if (this.query) {
                if (!!this.query.contentType) {
                    this.canSave = !!this.query.connectionId && !!this.query.sql;
                    this.canQuery = !!this.query.connectionId && !this.isLoading() && !!this.query.sql;
                } else {
                    this.canSave = !!this.query.connectionId && !this.queryBuilderService.isEmpty(this.query.model);
                    this.canQuery = !!this.query.connectionId && !this.isLoading() && !this.queryBuilderService.validate(this.query.model).error && !this.queryBuilderService.isEmpty(this.query.model) && !this.queryBuilderService.isColumnsEmpty(this.query.model);
                }
            }
        });
    }

    customSearchFn(term: string, item) {
        term = term.trim().toLowerCase();
        return item.name.toLowerCase().indexOf(term) > -1 || item.type.toLowerCase().indexOf(term) > -1;
    }

    isLoading(): boolean {
        return this.queryService.loading;
    }

    private async saveQuery(workspaceId, data) {
        try {
            const response: any = await this.queryService.save(workspaceId, data).toPromise();

            if (response.type === HttpEventType.Response) {
                this.query.id = response.id;
                this.view = 'query';

                this.toast.showToast(MessageBarType.success, 'The query was saved successfully');
                Office.context.ui.messageParent(JSON.stringify({ type: 'query-saved', query: this.query }));
            }

        } catch (response) {

            const message = response?.error?.message ?? response?.message;
            this.toast.showToast(MessageBarType.error, message);
        }
    }

    private async getWorkspaces() {
        try {
            const response = await this.appService.getWorkspaces().toPromise()

            let accounts = [];
            response.forEach((account) => {
                account.workspaces.forEach((workspace) => {
                    workspace['account'] = account.name;
                    accounts.push(workspace);
                });
            });
            this.accounts = accounts;
            this.init();

        } catch (response) {

            const message = response?.error?.message ?? response?.message;
            this.toast.showToast(MessageBarType.error, message);
        }
    }

    private async refreshConnections() {
        this.loadingConnections = true
        try {
            const response = await this.queryService.refreshConnections(this.query.workspaceId || this.workspaceIdDefault).toPromise()

            this.connections = response;

        } catch (response) {

            const message = response?.error?.message ?? response?.message;
            this.toast.showToast(MessageBarType.error, message);
        } finally {
            this.loadingConnections = false

        }
    }

    private async getSQL(connectionId, workspaceId, data) {
        try {
            const response = await this.queryService.getSQL(connectionId, workspaceId, data).toPromise()
            this.query.sql = response.toString();

            setTimeout(() => {
                this.editingSql = false;
            });

        } catch (response) {

            this.query.sql = '';

            const message = response?.error?.message ?? response?.message;
            this.toast.showToast(MessageBarType.error, message);
        }
    }

    private async refreshObjects(connection, workspaceId) {
        this.queryBuilderService.loadingObjects = true;
        try {
            const response = await this.queryService.refreshObjects(connection, workspaceId).toPromise()
            this.queryBuilderService.objects = [...response];
        } catch (response) {

            this.queryBuilderService.objects = [];
            this.queryBuilderService.object = null;

            const message = response?.error?.message ?? response?.message;
            this.toast.showToast(MessageBarType.error, message);
        } finally {
            this.queryBuilderService.loadingObjects = false;
        }
    }

    // TODO: use async
    private getConnectionById(connectionId) {
        return this.connections.find(f => f.id === connectionId);
    }

}
