import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';
import { AppService } from 'app/app.service';
import { Toast, MessageBarType } from '../_fabric/Toast';

@Component({
    templateUrl: 'startup.component.html',
    styleUrls: ['startup.component.scss']
})

export class StartupComponent {

    loading;
    isDialog;

    private toast;

    constructor(private router: Router, private appService: AppService) {

        this.isDialog = (<any>Office).context.isDialog;

        this.toast = new Toast();
    }

    public login() {

        let dialog;

        let processMessage = (arg) => {

            var messageFromDialog = JSON.parse(arg.message);
            if (messageFromDialog.messageType === "signinSuccess") {

                setTimeout(_ => {

                // TODO: issue =  https://jira.devart.com/browse/SKYVIA-8890, fix after resolve https://github.com/OfficeDev/office-js/issues/3820
                if (messageFromDialog.storage && typeof messageFromDialog.storage === 'string') {

                    const storage = JSON.parse(messageFromDialog.storage)
                    Object.keys(storage).forEach(key => {

                        if (key === 'Office API client') return

                        localStorage.setItem(key, storage[key]);
                    })
                }

                    this.router.navigate(['/']);
                }, 2000)
            }

        }

        let callback = (asyncResult) => {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                this.toast.showToast(MessageBarType.error, asyncResult.error.message);
            } else {
                dialog = asyncResult.value;
                dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
            }
        }

        Office.context.ui.displayDialogAsync(`${environment.HOST}/#/login`, { height: 55, width: 35, }, callback)
    }

}