

<div *ngIf="!connection" class="description">
    <h4>In order to run the query you should do following:</h4>
    <ol>
        <li><span>Select a Workspace.</span></li>
        <li><span>Select a Connection.</span></li>
        <li><span>Select a Table.</span></li>
        <li><span>Select Columns.</span></li>
        <li><span>Add and setup filters.</span></li>
    </ol>
</div>

<div class="spinner-wrapper" *ngIf="isLoading()">
    <div class="ms-Spinner ms-Spinner--large" fabricSpinner></div>
</div>

<ng-container *ngIf="!queryService.loading && connection">

    <div class="left">

        <div class="ms-TextField">
            <label for="filter" class="ms-Label">Object</label>
            <ng-select #selectObject placeholder="Select object..." 
                [(ngModel)]="queryBuilderService.object"
                (ngModelChange)="setObject(queryBuilderService.object)" 
                [items]="queryBuilderService.objects"
                bindLabel="name" bindValue="name" [clearable]="false" [searchable]="false"
                [loading]="queryBuilderService.loadingObjects">
                <ng-template ng-header-tmp>
                    <div class="search-wrapper">
                        <i class="ms-Icon ms-Icon--Search"></i>
                        <input type="text" (input)="selectObject.filter($event.target.value)"
                            placeholder="Type to filter..." class="ms-TextField-field" autofocus/>
                    </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    <span title="{{item.name}}">{{item.name}}</span>
                </ng-template>
                <ng-template ng-loading-tmp>
                    <div class="spinner-wrapper"><div class="ms-Spinner" fabricSpinner></div></div>
                </ng-template>
            </ng-select>
        </div>

        <div class="empty-area" *ngIf="!queryBuilderService.object"
            [ngClass]="{'has-object': queryBuilderService.objects}">
            <div class="empty-text">Columns Area</div>
        </div>

        <ng-container *ngIf="queryBuilderService.object">
            <div class="ms-TextField">
                <label for="filter" class="ms-Label">Columns</label>
            </div>
            <div class="accordion-wrapper">
                <accordion>
                    <accordion-group [isOpen]="queryBuilderService.object == obj.name" *ngFor="let obj of tables" (onCreate)="lazyLoadColumns(obj)" (isOpenChange)="initColumns(obj)">
                        <div accordion-heading >
                            <div class="accordion-header">
                                <i class="ms-Icon chevron"></i>
                                <div class="ms-CheckBox" [fabricCheckBox]="obj.$$allselected" [indeterminate]="initIndeterminate(obj)"  stopPropagation style="display: inline-block;">
                                    <input class="ms-CheckBox-input" tabindex="-1" type="checkbox" id="{{obj.name}}" 
                                    [checked]="obj.$$allselected"
                                    [(ngModel)]="obj.$$allselected" 
                                    (ngModelChange)="selectAllColumns(obj, $event)" 
                                    [indeterminate]="initIndeterminate(obj)">
                                    <label role="checkbox" class="ms-CheckBox-field" tabindex="0" for="{{obj.name}}">
                                        <span class="ms-Label" title="{{obj.name}}">{{obj.name}}</span> 
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div fabricSpinner *ngIf="obj.$$loading" class="loading"></div>
                        <ng-container *ngIf="obj.showColumns">        
                        <div class="search-wrapper">
                            <i class="ms-Icon ms-Icon--Search"></i>
                            <input class="ms-TextField-field" placeholder="Type to filter..." [autofocus] [(ngModel)]="obj.$$search" />
                        </div>
                        <div *ngFor="let column of obj.columns | filterBy: customSearchFn(obj.$$search)" class="column"> 
                                <div (onCreate)="initColumn(column, obj)" class="ms-CheckBox" [fabricCheckBox]="column.selected">
                                    <input class="ms-CheckBox-input" tabindex="-1" type="checkbox" id="{{obj.name}}_{{column.name}}" 
                                    [checked]="column.selected"
                                    [(ngModel)]="column.selected" 
                                    (ngModelChange)="changeColumn(column, obj)">
                                    <label role="checkbox" class="ms-CheckBox-field" tabindex="0" for="{{obj.name}}_{{column.name}}">
                                        <span class="ms-Label" title="{{column.name}}">{{column.name}}</span> 
                                    </label>
        
                                    <ng-container *ngIf="column.selected">
                                        <div class="ms-ContextualMenuExample" dropdown container="accordion">
                                            <button class="ms-Button" dropdownToggle>
                                                <span>{{findModelColum(column, obj).expression.name || 'Value' }}</span>
                                                <i class="ms-Icon chevron"></i>
                                            </button>
                                            <ul *dropdownMenu class="dropdown-menu">
                                                <query-builder-field-details [selectedObject]="findModelColum(column, obj)" [columns]="model.columns"></query-builder-field-details>
                                            </ul>
                                        </div>     
                                    </ng-container>
                                </div>    
                        </div>      
                    </ng-container>
                    </accordion-group>  
                </accordion>
            </div>
        </ng-container>
    </div>

    <div class="right">
        <div class="empty-area" *ngIf="!queryBuilderService.object">
            <div class="empty-text">Filter Area</div>
        </div>

        <div *ngIf="queryBuilderService.object && tables?.length">
            <form class="ms-TextField">
                <label for="filter" class="ms-Label">Filters</label>
                <ui-filter-item [tableRefs]="tables" [item]="model.filter" [connection]="connection" [workspaceId]="workspaceId"
                    (addSources)="addSources($event)" (recountSource)="recountSource($event)" (onIsDirty)="onIsDirty.emit($event)"></ui-filter-item>
            </form>

            <div class="ms-TextField">
                <label for="orderby" class="ms-Label">Order By</label>
                <ui-orderby [tables]="tables" [model]="model.orderBy" (addSources)="addSources($event)"
                    (recountSource)="recountSource($event)"></ui-orderby>
            </div>
        </div>
    </div>
</ng-container>