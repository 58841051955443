import { Injectable } from '@angular/core';

import { DatatypesService } from '../../services/datatypes.service';
import { ConditionService } from './condition.service';
import { Subject } from 'rxjs';

@Injectable()
export class QueryBuilderService {

    public objects = [];
    public object;

    public loadingObjects: boolean;

    public fieldChanged = new Subject<any>();

    constructor(
        private datatypesSvc: DatatypesService,
        private conditionSvc: ConditionService,
        ) {
    }

    createModel() {

        let filter = this.createFilterGroup();

        return {
            columns: [],
            filter: filter,
            orderBy: [],
            sources: [],
            // distinct: false,

            // $$filterList: [],
            // $$selectedObject: null
        };
    }

    createExpressionFunction(name: string, source: string, column: string, dataType: any) {

        if (source) {
            let values = [];
            values.push(this.createExpressionColumn(source, column, dataType));

            return {
                name: name,
                values: values,
                type: 'Function'
            };

        } else
            return {
                name: name,
                type: 'Function'
            };
    }

    createExpressionColumn(source: string, column: string, dataType: any) {

        return {
            source: source,
            column: column,
            type: 'Column',
            dataType: dataType,
        };
    }

    createExpressionConstant(value: string) {

        return {
            type: 'Constant',
            value: value,
        };
    }

    createExpressionParameter(value: string) {

        return {
            type: 'Parameter',
            name: value,
        };
    }

    createFilterGroup() {

        return {
            type: 'Group',
            operator: 'And',
            items: [],
            $$operatorName: 'All'
        };
    }

    createFilterCondition(source?, column?, dataType?, operator?, values = [], operatorName = null) {

        return {
            type: 'Condition',
            operator: operator,
            expression: this.createExpressionColumn(source, column, dataType),
            arguments: values,
            $$operatorName: operatorName
        };
    }

    isGroup(data): boolean {

        return data.some(s => { return s.type === 'Group'; });
    }

    isCondition(data): boolean {

        return data.some(s => { return s.type === 'Condition'; });
    }

    createTableRef(alias, name, relation, isChild) {

        let source;

        if (!!alias && alias !== name)
            source = {
                source: name,
                table: alias,
                relation: relation.name,
                label: alias + '' + relation.name,

                column: relation.column,
                relatedTable: relation.relatedTable,
                relatedColumn: relation.relatedColumn,
                isChild: isChild
            };
        else
            source = {
                table: name,
                relation: relation.name,
                label: relation.name,

                column: relation.column,
                relatedTable: relation.relatedTable,
                relatedColumn: relation.relatedColumn,
                isChild: isChild
            };

        return source;
    }

    createTableRefRevert(alias, name, relation) {

        return {
            table: relation.relatedTable,
            relation: relation.name,
            label: name,

            column: relation.relatedColumn,
            relatedTable: name,
            relatedColumn: relation.column,
            isChild: true
        };
    }

    validate(data) {

        let validate = {
            error: false,
            message: ''
        };

        let filters = this.getConditions(data.filter);
        if (filters !== undefined)
            filters.forEach(
                (el) => {

                    if (el.arguments.length === 0 && !(el.operator === 'IsNull' || el.operator === 'IsNotNull')) {
                        validate.error = true;
                        validate.message = 'New script cannot be generated because filter conditions are not set correctly.';
                    }

                    if ((el.operator === 'Between' || el.operator === 'NotBetween') && el.arguments.length !== 2) {
                        validate.error = true;
                    }

                    let dataType = this.datatypesSvc.getUserFriendlyTypeName(el.expression.dataType);

                    if (el.arguments.length)
                        el.arguments.forEach((item) => {

                            if (item.type == 'Parameter' || item.type == "Function") {
                                if (item.name == null || item.name == '') {
                                    validate.error = true;
                                }
                            } else {
                                if (dataType !== 'Text' && !(el.operator === 'IsNull' || el.operator === 'IsNotNull')) {
                                    if (item.value === null || item.value === '') {
                                        validate.error = true;
                                    }
                                }
                            }
                        });
                });


        if (data.orderBy.length > 0) {
            data.orderBy.forEach(function (el) {
                if (!el.expression.column) {
                    validate.error = true;
                    validate.message = '';
                }
            });
        }

        if (filters.length > 0 || data.orderBy.length > 0)
            if (data.columns.filter(f => { return f.disabled !== true; }).length === 0) {
                validate.error = true;
                validate.message = 'New script cannot be generated because no result fields are specified.';
            }

        return validate;
    }

    isEmpty(data): boolean {

        if (data.columns.length > 0)
            return false;

        if (this.getConditions(data.filter).length > 0)
            return false;

        if (data.orderBy.length > 0)
            return false;

        return true;
    }

    isColumnsEmpty(data): boolean {

        if (data.columns.filter((e) => e.disabled !== true).length > 0)
            return false;

        return true;
    }


    private getConditions(filter) {

        let items;

        if (filter.items !== undefined) {
            items = filter.items.filter(
                (e) => {
                    if (e.type === 'Condition' && e.disabled !== true)
                        return e;
                });

            filter.items.forEach(
                (element) => {
                    let childs = this.getConditions(element);
                    if (childs !== undefined)
                        childs.forEach(
                            (element) => {
                                items.push(element);
                            });
                });
        }

        return items;
    }

    getSourse(expression) {

        if (expression.type === 'Column')
            return expression;
        else
            return {
                source: expression.values[0].source,
                column: expression.values[0].column,
                type: '',
                dataType: expression.values[0].dataType,
            };
    }

    restoreConditions(filter) {

        if (filter.items !== undefined) {

            filter.items.forEach(
                (element) => {
                    if (element.expression !== undefined)
                        element = this.fillValues(element);

                    if (element.type === 'Group')
                        this.restoreConditions(element);
                });
        }

        return filter;
    }

    private fillValues(element) {

        let dataType = this.datatypesSvc.getUserFriendlyTypeName(element.expression.dataType);
        if (this.conditionSvc.getValueOperators(dataType).some(f => { return f.id === element.operator; })) {

            element.$$values = [];
            element.arguments.forEach(el => element.$$values.push(el.value ? el.value : el.name));
        } else
            if (dataType === 'Text') {

                element.$$listItems = [];

                element.arguments.forEach(el => element.$$listItems.push(el.value));

            } else
                if (dataType === 'DateTime' || dataType === 'Date') {

                    element.$$relative = element.arguments[0].name;
                }
                else {

                    element.$$values = [];
                    element.arguments.forEach(el => element.$$values.push(el.value ? el.value : el.name));
                }

        return element;
    }

}