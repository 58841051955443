import { Component, OnInit, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';

@Component({
    selector: 'show-input-errors',
    templateUrl: './show-input-errors.component.html',
    styleUrls: ['./show-input-errors.component.scss']
})
export class ShowInputErrorsComponent {

    private static readonly errorMessages = {
        'required': (params) => 'This field is required.',
        'name': () => 'The name is invalid.',
        'unique': () => 'This property name is already used.',
        'integer': () => 'Invalid integer.',
        'float': () => 'Invalid number.',
        'date': (params) => params.dirty ? (`Invalid date. The correct format is  MM/dd/yyyy${params.dateOnly ? '' : ' HH:mm[:ss]'}.`) : '',
        'guid': () => 'Invalid GUID.The correct format is { xxxxxxxx - xxxx - xxxx - xxxx - xxxxxxxxxxxx }.',
        'time': () => 'Invalid time. The correct format is HH:mm',
    };

    @Input()
    public control: AbstractControlDirective | AbstractControl;

    @Input()
    public submitted: boolean;

    @Input() params;

    shouldShowErrors(): boolean {

        return this.control && this.control.errors &&
            (this.control.invalid && this.control.dirty || this.control.touched || this.submitted);
    }

    listOfErrors(): string[] {
        return Object.keys(this.control.errors)
            .map(field => this.getMessage(field, { ...this.params, submitted: this.submitted, dirty: this.control.dirty }));
    }

    private getMessage(type: string, params: any) {
        return ShowInputErrorsComponent.errorMessages[type](params);
    }

}