import { Directive, Input } from '@angular/core';
import { Validator, FormControl, NG_VALIDATORS } from '@angular/forms';
import { Utils } from 'app/services/util';

import BigNumber from 'bignumber.js';

import * as dayjs from "dayjs";
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

@Directive({
    selector: '[validate][ngModel]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: ValidateDirective,
            multi: true
        }
    ]
})
export class ValidateDirective implements Validator {

    @Input('validate') validators;
    @Input() dateOnly;

    constructor(private utils: Utils) {
    }

    validate(c: FormControl) {

        let value = c.value;
        let model = {};

        if (!this.validators) {
            return null;
        }

        for (var i = 0; i < this.validators.length; ++i) {

            let validator = this.validators[i];
            let validatorFunc = this[validator];

            let valid = validatorFunc.call(this, value);
            if (!valid)
                model[validator] = true;
        }

        if (Object.keys(model).length) {
            return model;
        }

        return null;
    }

    private required(value) {

        if ((value === null) || (value === undefined) || (value === '') || (Number.isNaN(value)) ) {
            return false;           
        }
        
        return true;

    }

    private integer(value) {

        if (!value) {
            return true;
        }

        if (Number.isSafeInteger(parseFloat(value))) {
            return /^-?\d+$/.test(value);
        } else {
            const big = new BigNumber(value)
            return BigNumber.isBigNumber(big) && !big.isNaN();
        }
    }

    private natural(value) {

        return /^\d+$/.test(value);
    }

    private dayhours(value) {

        if (!this.natural(value)) {
            return false;
        }

        if (value <= 24) {
            return true;
        }

        return false;
    }

    private dayminutes(value) {

        if (!this.natural(value)) {
            return false;
        }

        if (value <= 1440) {
            return true;
        }

        return false;
    }

    private float(value) {

        if (!value) {
            return true;
        }

        return new RegExp('^-?[0-9]\\d*(\\' + this.utils.options.decimalSeparator + '\\d+)?$').test(value) && !isNaN(parseFloat(value));
    }

    private port(value) {

        if (!value) {
            return true;
        }

        if (!this.natural(value)) {
            return false;
        }

        if (value >= 1 && value <= 65535) {
            return true;
        }

        return false;
    }

    private email(value) {

        if (!value) {
            return true;
        }

        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|((\w[a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
        return regex.test(value);
    }

    private guid(value) {

        if (!value) {
            return true;
        }

        const regex = /^\{[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\}$/i;
        return regex.test(value);
    }

    private date(value) {

        return this.utils.isValidDate(value, this.dateOnly);
    }

    private time(value) {

        return dayjs(value, 'HH:mm', true).isValid() || dayjs(value, 'HH:mm:ss', true).isValid();
    }

}
