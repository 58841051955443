import { Directive, ElementRef, AfterViewInit } from '@angular/core';

import { ContextualMenu } from '../../_fabric/ContextualMenu';

@Directive({
  selector: '[fabricContextualMenu]'
})
export class ContextualMenuDirective implements AfterViewInit {

  private contextualMenu;

  constructor(private element: ElementRef) { }

  ngAfterViewInit() {

    setTimeout(() => {
      var ButtonElement = this.element.nativeElement.querySelector(".ms-Button");
      var ContextualMenuElement = this.element.nativeElement.querySelector(".ms-ContextualMenu");

      this.contextualMenu = new ContextualMenu(ContextualMenuElement, ButtonElement);
    });

  }
}
