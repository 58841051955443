export enum MessageBarType {
    info = 0,
    error,
    blocked,
    severeWarning,
    success,
    warning
}

export class Toast {

    // toast message template
    private _template =
                        `<div class="ms-MessageBar ms-MessageBar--{{type}}">
                            <div class="ms-MessageBar-content">
                                <div class="ms-MessageBar-icon">
                                    <i class="ms-Icon ms-Icon--{{iconType}}"></i>
                                </div>
                                <div class="ms-MessageBar-text">
                                    {{message}}
                                </div>
                                <div class="ms-MessageBar-icon">
                                    <i class="ms-Icon ms-Icon--Clear"></i>
                                </div>
                            </div>
                        </div>`;

    private _id = 0;
    private _timer: number;
    private _body = document.querySelector('body');

    constructor(private timer?: number) {
        this._timer = timer || 50;
    }

    public showToast(type, message) {

        const toastList = document.querySelectorAll('.toast');
        toastList.forEach((toast)=> {this._dismiss(null, toast)})

        let toastContent = this._template.replace('{{type}}', MessageBarType[type]);
        toastContent = toastContent.replace('{{message}}', message);
        toastContent = toastContent.replace('{{iconType}}', this.getIconType(type));

        let toast = document.createElement('div');
        toast.className = 'toast';
        toast.setAttribute('id', 'toast_' + (this._id++));

        toast.innerHTML = toastContent;

        const closeButton = toast.querySelector('.ms-Icon--Clear');
        closeButton.addEventListener('click', this._dismiss);

        // set the expiry of the toast
        setTimeout(()=> { this._dismiss(null, toast); }, 1000 * this._timer);

        this._body.appendChild(toast);

        // start toast appear animation
        toast.style.transform = 'translate3d(-50%, 200px, 0)';

        // complete toast appear animation
        setTimeout(()=> { toast.style.transform = 'translate3d(-50%, -20px, 0)'; }, 0);
    }

    public _dismiss(event, cachedToast?) {
        var toast = cachedToast || event.target.parentElement.parentElement.parentElement;
        toast.querySelector('.ms-Icon--Clear').removeEventListener('click', this._dismiss);
        toast.style.display = 'none';

        // remove the toast object from the body when the animation completes
        setTimeout(()=> {
            var el = document.querySelector('#' + toast.id);
            el.parentNode.removeChild(el);
        }, 300);
    }

    private getIconType(type) {

        switch(type) {
            case MessageBarType.success:
                return 'Completed';
            case MessageBarType.error:
                return 'ErrorBadge';
            case MessageBarType.blocked:
                return 'Blocked';
            case MessageBarType.severeWarning:
                return 'Warning';
            case MessageBarType.warning:
            case MessageBarType.info:
            default:
                return 'Info';
        }
        
    }

}
