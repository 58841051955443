import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OAuthService, OAuthSuccessEvent } from 'angular-oauth2-oidc';

@Injectable({ providedIn: 'root' })
export class AuthService {
    constructor(private oAuthService: OAuthService) { }

    getToken(): Observable<string> {
        return of(this.oAuthService.authorizationHeader())
    }

    login() {
        this.oAuthService.initCodeFlow();
    }
    
    logOut() {
        this.oAuthService.logOut();
    }

    loadDiscoveryDocument(): Promise<OAuthSuccessEvent> {
        return this.oAuthService.loadDiscoveryDocument();
    }

    tryLogin() {
        return this.oAuthService.tryLogin();
    }

    setupAutomaticSilentRefresh() {
        this.oAuthService.setupAutomaticSilentRefresh({}, 'access_token',);
    }

    getIdentityClaims() {
        return this.oAuthService.getIdentityClaims();
    }

    getEvents() {
        return this.oAuthService.events;
    }

}