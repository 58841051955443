import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AlertService } from 'app/services/alert.service';
@Injectable()
export class GalleryService {

    connectionDefs;

    constructor(
        private http: HttpClient,
        private alertService: AlertService) {
    }

    getconnectionDefs(workspaceId): Observable<any> {
        return this.http.get(`api/${workspaceId}/connectors/all`);
    }

    getCollections(workspaceId): Observable<any> {

        return this.http.get(`api/${workspaceId}/collections`);
    }

    getConnections(workspaceId): Observable<any> {

        return this.http.get(`api/${workspaceId}/queryconnections`);
    }

    getConnectors(privateOnly, workspaceId): Observable<any> {

        let params = new HttpParams();
        params = params.set('privateOnly', privateOnly.toString());

        return this.http.get(`api/${workspaceId}/queryconnectors`, { params });
    }

    getQueries(filter, workspaceId): Observable<any> {

        let params = new HttpParams();
        if (filter) {
            Object.keys(filter).forEach(key => {
                if (filter[key]){
                    params = params.set(key, filter[key].toString());
                }
            });
        }

        return this.http.get(`api/${workspaceId}/queries`, { params } );
    }

    getQuery(id, workspaceId): Observable<any> {

        return this.http.get(`api/${workspaceId}/queries/${id}`);
    }

    deleteQuery(item) {

        return this.alertService.open('Are you sure you want delete this query?')
            .then(() => {
                return new Promise((resolve, reject) => {
                    this.http.delete(`api/${item.workspaceId}/queries/${item.id}`)
                        .subscribe((response: any) => {

                            return resolve(response);
                        },
                        (response) => { return reject(response); });
                });
            });
    }

}
