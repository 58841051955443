import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'orderBy'
})
export class OrderPipe implements PipeTransform {

    static isString(value: any) {
        return typeof value === 'string' || value instanceof String;
    }

    static caseInsensitiveSort(a: any, b: any) {
        if (OrderPipe.isString(a) && OrderPipe.isString(b)) {
          return a.localeCompare(b);
        }
        return OrderPipe.defaultCompare(a, b);
    }

    static defaultCompare(a: any, b: any) {
        if (a == null) {
            return 1;
        }
        if (b == null) {
            return -1;
        }
        if (a === b) {
            return 0;
        }
        if (OrderPipe.isString(a) && OrderPipe.isString(b)) {
            return a.toLowerCase() > b.toLowerCase() ? 1 : -1;
        } else {
            return a > b ? 1 : -1;
        }
    }

    transform(value: any | any[], expression?: any, reverse?: boolean, isCaseInsensitive: boolean = false, comparator?: Function) {

        if (!value) {
            return value;
        }

        if (Array.isArray(expression)) {
            return this.multiExpressionTransform(value, expression, reverse, isCaseInsensitive, comparator);
        }

        if (!expression || expression.trim() === '') {
            return value;
        }

        if (Array.isArray(value)) {
            return this.sortArray(value.slice(), expression, reverse, isCaseInsensitive, comparator);
        }

        return value;

    }

    private sortArray(
        value: any[],
        expression?: any,
        reverse?: boolean,
        isCaseInsensitive?: boolean,
        comparator?: Function): any[] {

        let compareFn: Function;

        if (comparator && typeof comparator === 'function') {
          compareFn = comparator;
        } else {
          compareFn = isCaseInsensitive
            ? OrderPipe.caseInsensitiveSort
            : OrderPipe.defaultCompare;
        }

        const inlineReverse = expression.substr(0, 1) === '-';
        if (inlineReverse) {
            reverse = inlineReverse;
            expression = expression.substr(1, expression.length);
        }

        const array: any[] = value.sort((a: any, b: any): number => {

            if (!expression) {
                return compareFn(a, b);
            }

            return compareFn(a[expression], b[expression]);
        });

        if (reverse) {
            return array.reverse();
        }

        return array;
    }

    private multiExpressionTransform(
        value: any,
        expressions: any[],
        reverse?: boolean,
        isCaseInsensitive?: boolean,
        comparator?: Function): any {

        return expressions.reverse().reduce((result: any, expression: any) => {
            return this.transform(result, expression, reverse, isCaseInsensitive, comparator);
        }, value);
    }

}
