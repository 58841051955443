import { Component, Injectable, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
    selector: 'confirm',
    template: `
            <div class="modal-body text-center">
                <p>{{message}}</p>
                <div class="flex">
                    <button type="button" [ngClass]="okBtnClass" (click)="ok()">
                        <span class="ms-Button-label">{{ okBtnName }}</span>
                    </button>
                    <ng-container *ngIf="!hideCloseBtn">
                        <button type="button" [ngClass]="closeBtnClass" (click)="cancel()">
                            <span class="ms-Button-label">{{ closeBtnName }}</span>
                        </button>
                    </ng-container>
                </div>
              </div>
            `,
   styles: [`
        p {
            text-align: center;
            font-size: 14px;
            word-wrap: break-word;
        }

        .flex {
            display: flex;
            justify-content: center;
            align-items: center;
        }

    `]
})
export class ConfirmComponent {

    message;

    okBtnName =  'Ok';
    okBtnClass = 'ms-Button ms-Button--primary';

    closeBtnName = 'Cancel';
    closeBtnClass = 'ms-Button';

    hideCloseBtn = false;

    public onClose: Subject<any> = new Subject();

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.keyCode == 13) {
            if (this.closeBtnClass === 'primary') {
                this.cancel();
            } else {
                this.ok();
            }
        }
    }

    ok(): void {
        this.onClose.next(true);
    }

    cancel(): void {
        this.onClose.next(false);
    }
}

@Injectable()
export class AlertService {

    modalRef: BsModalRef;

    constructor(private modalService: BsModalService) {

    }

    public confirm(message) {
        return confirm(message);      // TODO: need sync modal window
    }

    public alert(message) {
        return this.open(message, { hideCloseBtn: true });
    }

    public open(message, config?) {
        return new Promise<void>((resolve, reject) => {

            const initialState = { message: message, ...config };
            const className = config && config.class ? config.class : 'modal-sm';
            this.modalRef = this.modalService.show(ConfirmComponent, { initialState, class: className, animated: true, ignoreBackdropClick: true });

            this.modalRef.content.onClose.subscribe((value) => {

                this.modalRef.hide();

                if (value) {
                    resolve();
                } else {
                    reject();
                }

            });
        });
    }

}
