import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { OAuthEvent } from 'angular-oauth2-oidc';

@Injectable({
    providedIn: 'root',
    useClass: AuthService
})
export abstract class AuthSharedService {

    abstract getToken(): Observable<string>;
    abstract login(): void;
    abstract logOut(): void;
    abstract tryLogin(): Promise<boolean>;

    abstract setupAutomaticSilentRefresh(): void;

    abstract getIdentityClaims(): Array<string>;

    abstract getEvents(): Observable<OAuthEvent>
}
