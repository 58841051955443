import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthSharedService } from './auth/auth-shared.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class AppService {

    appInsights: ApplicationInsights;

    constructor(private http: HttpClient, private oauthService: AuthSharedService) {

    }

    setWorkspaceId(workspaceId) {
        localStorage.setItem('workspaceId',  workspaceId);
        return workspaceId;
    }

    getWorkspaceId() {
        return parseInt(localStorage.getItem('workspaceId'), 10);
    }

    getDefaultWorkspace(){
        return this.http.get('api/profile/defaultworkspace');
    }

    getWorkspaces(): Observable<any[]> {
        return this.http.get<any[]>('api/workspaces');  
    }

    initAppInsights() {
        const claims: any = this.oauthService.getIdentityClaims();

        if(!claims) return;

        const uid = claims.uid;
        const telemetryKey = 'af3d6848-29e2-4d25-8132-5c5ba59da97e';

        if (environment.envName === 'prod') {

            this.appInsights = new ApplicationInsights({
                config: {
                    appId: 'office',
                    instrumentationKey: telemetryKey,
                    disableAjaxTracking: true
                }
            });
            this.appInsights.loadAppInsights();

            this.appInsights.setAuthenticatedUserContext(uid, null, false);
        }
    }

    trackEvent(name, params?) {

        if (environment.envName === 'prod') {

            this.appInsights.trackEvent({ name: name }, params);
        }
        else {
            console.log('trackEvent ', name, params);
        }
    }

}