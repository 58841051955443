import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterBy'
})
export class FilterPipe implements PipeTransform {

    static getValue(value: any): any {
        return typeof value === 'function' ? value() : value;
    }

    static isFoundOnWalking(value, key) {
        let walker = value;
        let found = false;
        do {
            if (
                walker.hasOwnProperty(key) ||
                Object.getOwnPropertyDescriptor(walker, key)
            ) {
                found = true;
                break;
            }
        } while ((walker = Object.getPrototypeOf(walker)));

        return found;
    }

    private isMatching(filter, val) {
        switch (typeof filter) {
            case 'boolean':
                return this.filterByBoolean(filter)(val);
            case 'string':
                return this.filterByString(filter)(val);
            case 'object':
                return this.filterByObject(filter)(val);
        }
        return this.filterDefault(filter)(val);
    }



    private filterDefault(filter: any): (value: any) => boolean {
        return (value: any) => filter === undefined || filter == value;
    }

    private filterByBoolean(filter) {
        return value => Boolean(value) === filter;
    }

    private filterByString(filter) {
        if (filter) {
            filter = filter.toLowerCase();
        }
        return value =>
            !filter ||
            (value ? ('' + value).toLowerCase().indexOf(filter) !== -1 : false);
    }

    private filterByObject(filter) {
        return value => {
            // tslint:disable-next-line: forin
            for (const key in filter) {
                if (!value || !FilterPipe.isFoundOnWalking(value, key)) {
                    return false;
                }

                if (!this.isMatching(filter[key], FilterPipe.getValue(value[key]))) {
                    return false;
                }
            }

            return true;
        };
    }

    transform(items: any, filter: any): any {

        if (!items || !filter) {
            return items;
        }

        switch (typeof filter) {
            case 'boolean':
                return items.filter(this.filterByBoolean(filter));
            case 'string':
                return items.filter(this.filterByString(filter));
            case 'object':
                return items.filter(this.filterByObject(filter));
            case 'function':
                return items.filter(filter);
        }
        return items.filter(this.filterDefault(filter));
    }

}
