
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent, HttpInterceptor, HttpResponse, HttpHeaders } from '@angular/common/http';

import { Router } from '@angular/router';

import { environment } from 'environments/environment';
import { AppService } from './app.service';
import { Toast, MessageBarType } from './_fabric/Toast';

import { AuthService } from './auth/auth.service';

import { Observable, throwError } from 'rxjs';
import { switchMap, tap, catchError } from 'rxjs/operators';

@Injectable()
export class ErrorResponseInterceptor implements HttpInterceptor {

  toast;

  constructor(private router: Router, private appService: AppService) {

    this.toast = new Toast();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req)
      .pipe(
        catchError((response: HttpErrorResponse)=> {

          if (response?.error?.error?.startsWith('invalid_grant')) { //TODO: refactoring

              this.toast.showToast(MessageBarType.error, 'Your session has expired. Please relogin');

              this.router.navigate(['startup']);
              return; 
          }

          switch (response.status) {
              case 500:
                // TODO
                break;
              case 404:
                // this.router.navigate(['/404']);
                break;
              case 401:
                this.router.navigate(['startup']);
                break;
          }

          // NOTE: workaround - in Office area, for some unknown reason, 401 response.error is specified as JSON
          let message = response?.error?.message ?? response?.message;
          if(typeof response.error === 'string') {
            message = JSON.parse(response.error);
            return throwError(message);
          }

          return throwError({message});
        })
        // tap((event: HttpEvent<any>) => { }, (response: any) => {
        //   if (response instanceof HttpErrorResponse) {

        //     if (response.error.error === 'invalid_grant') {

        //       this.toast.showToast(MessageBarType.error, 'Your session has expired. Please relogin');

        //       this.router.navigate(['startup']);
        //       return; 
        //     }

        //     switch (response.status) {
        //       case 500:
        //         // TODO
        //         break;
        //       case 404:
        //         //     this.router.navigate(['/404']);
        //         break;
        //       case 401:
        //         this.router.navigate(['startup']);
        //         break;
        //     }
        //   }
        // })
      )

  }

}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.startsWith('http')) {
        return next.handle(request);
    }

    return this.authService.getToken()
      .pipe(
        switchMap(token => {
          const headers = request.headers
            .set('Authorization', token)
          const requestClone = request.clone({
            headers
          });
          return next.handle(requestClone);
        })
      );
  }
}


@Injectable()
export class APIInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.startsWith('http')) {
      return next.handle(request);
    }
    if (request.url.startsWith('api')) {
      request = request.clone({ url: `${environment.API_ENDPOINT}/${request.url}` });
      return next.handle(request);
    }

    if (request.method === 'DELETE' || request.method === 'PUT') {
      request = request.clone({ headers: request.headers.set('X-HTTP-Method-Override', request.method) });
      request = request.clone({ method: 'POST' });
    }

    return next.handle(request);
  }
}

@Injectable()
export class DateConverterInterceptor implements HttpInterceptor {
  private dateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/;

  private utcDateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/;

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
    .pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.convertDates(event.body);
        }
      })
    )
  }

  private convertDates(object: Object) {
    if (!object || !(object instanceof Object)) {
      return;
    }

    if (object instanceof Array) {
      for (const item of object) {
        this.convertDates(item);
      }
    }

    for (const key of Object.keys(object)) {
      const value = object[key];

      if (value instanceof Array) {
        for (const item of value) {
          this.convertDates(item);
        }
      }

      if (value instanceof Object) {
        this.convertDates(value);
      }

      if (typeof value === 'string' && (this.dateRegex.test(value) || this.utcDateRegex.test(value))) {
        object[key] = new Date(value);
      }
    }
  }
}
