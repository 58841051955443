
import { Injectable } from '@angular/core';

@Injectable()
export class FilterItemService {

  defaultGroupType = 'And';

  createGroup() {
    return {
      type: 'Group',
      operator: this.defaultGroupType,
      items: []
    };
  }

  createCondition() {
    return {
      type: 'Condition',
      field: {
        source: null,
        column: null
      },
      operator: null,
      values: [null]
    };
  }

  visitFilterItem(filterItem, groupFunc, conditionFunc) {

    if (filterItem.type === 'Group') {

      if (groupFunc) {
        groupFunc(filterItem);
      }

      filterItem.items.forEach((item)=> {
        this.visitFilterItem(item, groupFunc, conditionFunc);
      });
    } else if (filterItem.type === 'Condition') {
        if (conditionFunc) {
          conditionFunc(filterItem);
        }
      }
  }

}
