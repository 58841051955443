import { Pipe, PipeTransform } from '@angular/core';
import { GalleryService } from 'app/gallery/gallery.service';

@Pipe({ name: 'connectionType' })
export class ConnectionTypePipe implements PipeTransform {

    constructor(private galleryService: GalleryService) {}


    transform(value) {
        // TODO: delete after released connector
        var connectionDefs = {                    
            activecampaign: {name: "ActiveCampaign"},
            amazons3: {name: "Amazon S3"},
            azuredwh: {name: "SQL Data Warehouse"},
            azurefilestorage: {name: "Azure File Storage"},
            backup: {name: "Backup"},
            bigcommerce: {name: "BigCommerce"},
            bigquery: {name: "Google BigQuery"},
            box: {name: "Box"},
            demo: {name: "Demo DB"},
            dropbox: {name: "Dropbox"},
            dynamics: {name: "Dynamics CRM"},
            exacttarget: {name: "Marketing Cloud"},
            freshbooks: {name: "FreshBooks"},
            freshdesk: {name: "Freshdesk"},
            ftp: {name: "FTP"},
            googleapps: {name: "GoogleApps"},
            googledrive: {name: "Google Drive"},
            hubspot: {name: "HubSpot"},
            jira: {name: "Jira"},
            magento: {name: "Magento"},
            mailchimp: {name: "MailChimp"},
            marketo: {name: "Marketo"},
            mysql: {name: "MySQL"},
            netsuite: {name: "NetSuite"},
            odata: {name: "OData"},
            onedrive: {name: "OneDrive"},
            oracle: {name: "Oracle"},
            pipedrive: {name: "Pipedrive"},
            podio: {name: "Podio"},
            postgresql: {name: "PostgreSQL"},
            quickbooks: {name: "QuickBooks"},
            redshift: {name: "Amazon Redshift"},
            rest: {name: "REST"},
            salesforce: {name: "Salesforce"},
            sendpulse: {name: "SendPulse"},
            sftp: {name: "SFTP"},
            shipstation: {name: "ShipStation"},
            shopify: {name: "Shopify"},
            slack: {name: "Slack"},
            sqlserver: {name: "SQL Server"},
            streak: {name: "Streak"},
            stripe: {name: "Stripe"},
            sugar: {name: "SugarCRM"},
            wordpress: {name: "WordPress"},
            zendesk: {name: "Zendesk"},
            zoho: {name: "Zoho CRM"},
            zohoinventory: {name: "Zoho Inventory"},
            zohopeople: {name: "Zoho People"}                    
            };
           
            var connectionDef = this.galleryService.connectionDefs ? this.galleryService.connectionDefs[value] : connectionDefs[value];
            return connectionDef ? connectionDef.name : value;
        };
}