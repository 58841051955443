import { Component, ElementRef, AfterViewInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { DatePicker } from '../../_fabric/DatePicker';

import { Utils } from 'app/services/util';

export const PICKER_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatePickerComponent),
  multi: true
};

const noop = () => {
};

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [PICKER_VALUE_ACCESSOR]
})
export class DatePickerComponent implements AfterViewInit, ControlValueAccessor {

  @Input() title: string;
  @Input() dateOnly: boolean;
  @Input() minDate: Date;
  @Input() utc: boolean;
  model: Date;
  modelValue;

  constructor(
    private utils: Utils, 
    private element: ElementRef) { }

    ngAfterViewInit() {

    let datePicker: any = new DatePicker(this.element.nativeElement, { editable: true });

    datePicker.picker.on('open', () => {
      let date:any = new Date(this.model || Date.now());
      if (isNaN(date))
        return ;

      datePicker.picker.set("highlight", date);
    })

    datePicker.picker.on('set', (value) => {
      if (value.select) {
        this.pickerChange(new Date(value.select));
        datePicker.picker.close()
      }
    })

  }

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  registerOnChange(fn: any) {
      this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
      this.onTouchedCallback = fn;
  }

  // $formatters - model => view
  writeValue(value: any) {

      if (value !== this.model) {

          this.model =  !this.utils.isDate(value) ? value :
                              this.utc ? this.utils.convertLocalDatetoUTCDate(value) : value;

          if (value && this.utils.isValidDate(value, this.dateOnly)) {
              this.modelValue = value;
          }
      }
  }

  // $parsers - view => model
  modelChange(value) {

      this.model = value;

      const callback = !this.utils.isDate(value) ? value :
                          this.utc ? this.utils.convertUTCDateToLocalDate(this.model) : this.model;

      if (value && this.utils.isValidDate(value, this.dateOnly)) {
          this.modelValue = value;
      }

      this.onChangeCallback(callback);
  }

  selectionDone(dropdown) {
      setTimeout(() => {
          dropdown.hide();
      });
  }

  pickerChange(value) {

      const date = this.utils.getValidDate(this.model);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      const dateTime = this.utils.createDate(value, hours, minutes, seconds);

      this.modelChange(dateTime);
  }

  inputChange(value) {

      if (value && this.utils.isValidDate(value, this.dateOnly)) {
          value = this.utils.convertUTCDateToLocalDate(new Date(value));
      }
      this.modelChange(value);
  }


//   @Input() title: string;
//   @Input('dateOnly') dateOnly: any;

//   private _value: any;

//   //by the Control Value Accessor
//   private onTouched: () => void = noop;
//   private onChange: (_: any) => void = noop;

//   constructor(private element: ElementRef) { }

//   ngAfterViewInit() {

//     let datePicker: any = new DatePicker(this.element.nativeElement, { editable: true });

//     datePicker.picker.on('open', () => {
//       let date:any = new Date(this.value || Date.now());
//       if (isNaN(date))
//         return ;

//       datePicker.picker.set("highlight", date);
//     })

//     datePicker.picker.on('set', (value) => {
//       if (value.select) {
//         this.value = value.select;
//         console.log(value.select)
//       }
//     })

//   }

//   /** ControlValueAccessor interface methods. **/
//   writeValue(value: any) {
//     if (value !== this._value) {
//       this._value = value;
//     }
//   }

//   registerOnChange(fn: any) {
//     this.onChange = fn;
//   }

//   registerOnTouched(fn: any) {
//     this.onTouched = fn;
//   }

//   /** Value. **/
//   get value(): any {

//     let value = this._value;

//     if (this._value && moment(this._value).isValid()) {
//       let m = moment(this._value).utc();
//       if (this.dateOnly && !m.hours())
//         value = m.format(AppSettings.dateFormat);
//       else
//         if (m.seconds())
//           value = m.format(AppSettings.dateTimeSecondsFormat);
//         else
//           value = m.format(AppSettings.dateTimeFormat);
//     }

//     return value;
//   }

//   set value(v: any) {
//     if (v !== this._value) {

//       let value = v;

//       let date: any = new Date(v);
//       if (!isNaN(date)) {
//         let dateUTC = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()))
//         value = moment(dateUTC).toJSON();
//       }
// console.log('set value(): ',value)
//       this._value = value;
//       this.onChange(value);
//     }
//   }

}
