import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { QueryBuilderService } from 'app/query/query-builder/query-builder.service';

@Component({
    selector: 'ui-orderby',
    template: `
        <div *ngFor="let item of model; let i = index;" class="condition">
            <ui-orderby-item [item]="item" [tables]="tables" [index]="i" (addSources)="addSources.emit($event)" (removeItem)="remove($event)"></ui-orderby-item>
        </div>
        <button type="button" (click)="add()" title="Add" class="ms-Button ms-CommandBarItem-link">
            <span class="flex-container">
                <i class="ms-Icon ms-Icon--Add"></i>
                <span>Add</span>
            </span>
        </button>
    `,
    styleUrls: ['./orderby.component.scss'],
})
export class OrderbyComponent {

    @Input() tables;
    @Input() model;

    @Output() addSources: EventEmitter<any> = new EventEmitter<any>();
    @Output() recountSource: EventEmitter<any> = new EventEmitter<any>();

    constructor(private queryBuilderService: QueryBuilderService) {

    }

    add() {
        this.model.push({ expression: this.queryBuilderService.createExpressionColumn(null, null, null) });
    }

    remove(index) {
        this.model.splice(index, 1);
        this.recountSource.emit();
    }

}

@Component({
    selector: 'ui-orderby-item',
    template: `
    <div class="ms-Dropdown"> 
        <ng-select [(ngModel)]="tableRef" (ngModelChange)="sourceChanged(tableRef)" [items]="tables" [clearable]="false" [searchable]="false" appendTo="body">
            <ng-template ng-label-tmp let-item="item">
                <span class="text">{{item.name}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                <span title="{{item.name}}">{{item.name}}</span>
            </ng-template>
        </ng-select>
    </div>
    <div class="spinner-wrapper" *ngIf="!tableRef?.columns">
        <div class="ms-Spinner" fabricSpinner></div>
    </div>
    <ng-container *ngIf="tableRef?.columns">
        <div class="ms-Dropdown">  
            <ng-select [(ngModel)]="item.expression.column" (ngModelChange)="columnChanged(item)" [items]="tableRef.columns | orderBy:'name'"
            [clearable]="false" bindValue="name" bindLabel="name" appendTo="body"></ng-select>
        </div>
    </ng-container>
    <button (click)="item.descending = false" title="ASC" aria-label="ASC" class="ms-Button ms-CommandBarItem-link">
        <span class="flex-container">
            <i class="ms-Icon" [ngClass]="{'ms-Icon--RadioBtnOn': !item.descending ,'ms-Icon--RadioBtnOff': item.descending }"></i>
            <span>ASC</span>
        </span>
    </button>

    <button (click)="item.descending = true" title="DESC" aria-label="DESC" class="ms-Button ms-CommandBarItem-link">
        <span class="flex-container">
            <i class="ms-Icon" [ngClass]="{'ms-Icon--RadioBtnOn': item.descending ,'ms-Icon--RadioBtnOff': !item.descending }"></i>
            <span>DESC</span>
        </span>
    </button>
    
    <button (click)="remove()" title="Remove" aria-label="Remove" class="ms-Button ms-CommandBarItem-link remove">
        <span class="flex-container">
            <i class="ms-Icon ms-Icon--Delete"></i>
        </span>
    </button>
    `,
    styleUrls: ['./ui-orderby-item.component.scss'],
})
export class OrderbyItemComponent implements OnInit {

    @Input() item;

    @Input() tables;
    @Input() index;

    @Output() addSources: EventEmitter<any> = new EventEmitter<any>();
    @Output() removeItem: EventEmitter<any> = new EventEmitter<any>();

    tableRef;

    ngOnInit() {

        if (this.item.expression.source) {
            this.tableRef = this.findByName(this.tables, this.item.expression.source);
        }

        if (!this.tableRef) {
            this.tableRef = this.tables[0];
            this.item.expression.source = this.tableRef.name;
        }

        if (!this.item.expression.column) {
            this.item.expression.column = this.tableRef.columns[0].name;
            this.columnChanged(this.item);
        }
    }

    remove() {
        this.removeItem.emit(this.index);
    }

    sourceChanged(table) {

        this.item.expression.source = table.name;
        if (table.columns) {
            this.item.expression.column = table.columns[0].name;
        }

        if (!table.columns) {
            table.get().then((result)=> {
                table.columns = result.columns;
                this.item.expression.column = table.columns[0].name;

            });
        }


        if (table.tableRef) {
            this.addSources.emit(table.tableRef)
        }
    }

    columnChanged(column) {

        var column = this.tableRef.columns.find(f => f.name == this.item.expression.column);
        if (column) {
            this.item.expression.dataType = column.dataType;
        }
    }

    private findByName(items, name) {
        for (let i = 0; i < items.length; i++) {
            if (items[i].name == name) {
                return items[i];
            }
        }
        return null;
    }

}
