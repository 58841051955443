
<div *ngIf="!(view === 'Query' || view === 'Settings')">
  <div  class="ms-CommandBar" role="menubar">
    <div class="ms-TextField workspace-menu">
      <label class="ms-Label">Workspace:</label>
      <ng-select [items]="accounts" [(ngModel)]="workspaceIdDefault" (ngModelChange)="selectWorkspace(workspaceIdDefault)" bindLabel="name" bindValue="id" groupBy="account"
        [clearable]="false" [searchable]="false">

        <ng-template ng-optgroup-tmp let-item="item">
            <div class="group">
            {{item.account}}
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <div class="workspace">
            <div class="name">
              <span class="title">{{item.name}}</span>
              <span class="details">
                  <span class="default" *ngIf="item.isDefault">Default</span>
                  <span class="personal" *ngIf="item.isPersonal">Personal</span>
                  <span class="gray" *ngIf="item.members <= 1">No sharing</span>
                  <span class="gray" *ngIf="item.members > 1">Common to {{item.members}} users</span>
              </span>
            </div>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="ms-CommandBar border-bottom" role="menubar" >
    <div role="group" class="ms-CommandBar-primaryCommand">
      <div class="ms-OverflowSet-item">
        <button class="ms-Button ms-Button--command" (click)="newQuery()" title="New Query">
          <span class="flex-container">
            <i class="ms-Icon ms-Icon--Add"></i>
            <span>Query</span>
          </span>
        </button>
      </div>

      <div dropdown>
        <button class="ms-Button ms-Button--command ms-CommandBarItem-link" title="Refresh" dropdownToggle>
          <span class="flex-container">
            <i class="ms-Icon ms-Icon--Refresh"></i>
            <span>Refresh</span>
            <i class="ms-Icon ms-Icon--ChevronDown menu-Icon"></i>
          </span>
        </button>
        <ul *dropdownMenu class="dropdown-menu">
          <li class="ms-ContextualMenu-item">
            <a class="ms-ContextualMenu-link" (click)="refresh()">
              Current Sheet
            </a>
          </li>
          <li class="ms-ContextualMenu-item">
            <a class="ms-ContextualMenu-link" (click)="refreshAll()">
              All Sheets
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div role="group" class="ms-OverflowSet ms-CommandBar-secondaryCommand">
      <div dropdown>
        <button class="ms-Button ms-Button--commandBar ms-CommandBarItem-link" title="Group By ..." dropdownToggle>
          <span class="ms-Icon ms-Icon--GroupList"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <li class="ms-ContextualMenu-item ms-ContextualMenu-item--header">Group BY</li>
          <li class="ms-ContextualMenu-item ">
            <a class="ms-ContextualMenu-link" [ngClass]="{'is-selected':tab == 'Sources'}"
              (click)="groupByConnectors(false)">
              Sources
            </a>
          </li>
          <li class="ms-ContextualMenu-item ">
            <a class="ms-ContextualMenu-link" [ngClass]="{'is-selected':tab == 'Connections'}"
              (click)="groupByConnections()">
              Connections
            </a>
          </li>
          <li class="ms-ContextualMenu-item ms-ContextualMenu-item--divider"></li>
          <li class="ms-ContextualMenu-item ">
            <a class="ms-ContextualMenu-link" [ngClass]="{'is-selected':tab == 'Queries'}" (click)="allQueries()">
              No grouping 
            </a>
          </li>
        </ul>
      </div>

      <div class="ms-OverflowSet-item" dropdown>
        <button class="ms-Button ms-Button--commandBar ms-CommandBarItem-link" title="Show/Hide public queries" dropdownToggle>
          <span [className]="privateOnly ? 'ms-Icon ms-Icon--FabricUserFolder' : 'ms-Icon ms-Icon--FabricNetworkFolder'"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
          <li class="ms-ContextualMenu-item ms-ContextualMenu-item--header">
                Show queries
          </li>
          <li class="ms-ContextualMenu-item ">
            <a class="ms-ContextualMenu-link link-with-icon" [ngClass]="{'is-selected':!privateOnly }"
              (click)="togglePrivate(false)">
              <span class="flex-container">
                <i class="ms-Icon ms-Icon--FabricNetworkFolder"></i>
                <span class="ms-ContextualMenu-itemText">Personal and public</span>
              </span>
            </a>
          </li>
          <li class="ms-ContextualMenu-item ">
            <a class="ms-ContextualMenu-link link-with-icon" [ngClass]="{'is-selected': privateOnly }"
              (click)="togglePrivate(true)">
              <span class="flex-container">
                <i class="ms-Icon ms-Icon--FabricUserFolder"></i>
                <span class="ms-ContextualMenu-itemText">
                  Personal only
                </span>
              </span>
            </a>
          </li>
        </ul>
      </div>

      <div class="ms-OverflowSet-item" dropdown>
        <button class="ms-Button ms-Button--commandBar ms-CommandBarItem-link" title="More" dropdownToggle>
          <span class="ms-Icon ms-Icon--More"></span>
        </button>
        <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" style="min-width: 142px">
          <li class="ms-ContextualMenu-item user">
            <div class="name">{{userName}}</div>
            <div class="email">{{userEmail}}</div>
          </li>
          <li class="ms-ContextualMenu-item ms-ContextualMenu-item--divider"></li>
          <li class="ms-ContextualMenu-item ">
            <a class="ms-ContextualMenu-link" (click)="selectSettings()">
              Settings
            </a>
          </li>
          <li class="ms-ContextualMenu-item ">
            <a class="ms-ContextualMenu-link" href="https://docs.skyvia.com/" target="_blank">
              Documentation
            </a>
          </li>
          <li class="ms-ContextualMenu-item ">
            <a class="ms-ContextualMenu-link" href="https://support.skyvia.com/" target="_blank">
              Support Portal
            </a>
          </li>
          <li class="ms-ContextualMenu-item ms-ContextualMenu-item--divider"></li>
          <li class="ms-ContextualMenu-item ">
            <a class="ms-ContextualMenu-link" (click)="logout()">
              Logout
            </a>
          </li>
        </ul>
      </div>
    </div>

  </div>
</div>
<section>


  <div class="spinner-wrapper" *ngIf="loading">
    <div class="ms-Spinner ms-Spinner--large" fabricSpinner></div>
  </div>

  <div class="ms-Pivot">

    <div [ngSwitch]="view">

      <div *ngSwitchCase="'Sources'">

        <div class="search-wrapper">
          <i class="ms-Icon ms-Icon--Search"></i>
          <input class="ms-TextField-field" placeholder="Search..." [(ngModel)]="searchSources" />
        </div>

        <ng-container *ngIf="!loading">

          <div *ngIf="!(connectors | filterBy: customSearch(searchSources))?.length && !searchSources" class="ms-DatePicker-table">
            <p>No saved connectors</p>
          </div>

          <div *ngIf="!(connectors | filterBy: customSearch(searchSources))?.length && searchSources" class="ms-DatePicker-table">
            <p>No saved connectors that match search condition</p>
          </div>

          <ul class="ms-List">
            <li class="ms-ListItem selectable" tabindex="0"
              *ngFor="let c of connectors | filterBy: customSearch(searchSources) | orderBy:'name'"
              (click)="filterQueries(c.name, {connector: c.name})">

              <span class="ms-ListItem-image icon-middle" [ngClass]="c.name"></span>
              <span class="ms-ListItem-Text">{{c.name | connectionType}}</span>
              <span class="ms-ListItem-actions">
                <span>{{c.count}}</span>
                <i class="ms-Icon ms-Icon--ChevronRight"></i>
              </span>

            </li>
          </ul>
        </ng-container>
      </div>

      <div *ngSwitchCase="'Connections'">

        <div class="search-wrapper">
          <i class="ms-Icon ms-Icon--Search"></i>
          <input class="ms-TextField-field" placeholder="Search..." [(ngModel)]="searchConnections" />
        </div>
        <ng-container *ngIf="!loading">
          <div *ngIf="!(connections | filterBy: customSearch(searchConnections)).length && !searchConnections" class="ms-DatePicker-table">
            <p>No saved connections</p>
          </div>

          <div *ngIf="!(connections | filterBy: customSearch(searchConnections)).length && searchConnections" class="ms-DatePicker-table">
            <p>No saved connections that match search condition</p>
          </div>

          <ul class="ms-List">
            <li class="ms-ListItem selectable" tabindex="0"
              *ngFor="let c of connections | filterBy: customSearch(searchConnections) | orderBy:'name'"
              (click)="filterQueries(c.name, {connection: c.id})">

              <span class="ms-ListItem-image icon-middle" [ngClass]="c.connectionType"></span>
              <span class="ms-ListItem-Text" title="{{c.name}}">{{c.name}}</span>
              <span class="ms-ListItem-actions">
                <span>{{c.count}}</span>
                <i class="ms-Icon ms-Icon--ChevronRight"></i>
              </span>

            </li>
          </ul>
        </ng-container>
      </div>

      <div *ngSwitchCase="'Queries'">

        <header class="header" *ngIf="navHistory?.length > 1">
          <button class="ms-Button ms-Button--command ms-CommandBarItem-link" (click)="back()" title="Back">
            <i class="ms-Icon ms-Icon--Back "></i>
          </button>

          <span class="title">{{filterName | connectionType}}</span>
        </header>

        <div class="search-wrapper">
          <i class="ms-Icon ms-Icon--Search"></i>
          <input class="ms-TextField-field" placeholder="Search..." [(ngModel)]="filter.search" autofocus/>
        </div>
        <ng-container *ngIf="!loading">
          
          <div *ngIf="!queries.length && !filter.search" class="ms-DatePicker-table">
            <p>No saved queries</p>
          </div>

          <div *ngIf="!(queries | filterBy: customSearch(filter.search)).length && filter.search" class="ms-DatePicker-table">
            <p>No saved queries that match search condition</p>
          </div>

          <ul class="ms-List query-gallery-table" [ngClass]="{'history': navHistory?.length > 1}">
            <li class="ms-ListItem selectable" tabindex="0" *ngFor="let q of queries | filterBy: customSearch(filter.search) | orderBy:'name'"
              (click)="openQuery(q)">
              <div class="ms-ListItem-image icon-middle" [ngClass]="q.connectionType"></div>

              <div class="ms-ListItem-Text">
                <span class="ms-ListItem-secondaryText" title="{{q.name}}">{{q.name}}</span>
                
                <span class="ms-ListItem-tertiaryText">
                  <i class="ms-Icon" [ngClass]="q.sharingType ? 'ms-Icon--Globe' : 'ms-Icon--Contact'"></i>
                  <span *ngIf="q.description" title="{{q.description}}"> {{q.description}} </span>
                  <span *ngIf="!q.description" class="sharingType">{{ q.sharingType ? 'Public' : 'Personal'}}</span>
                </span>
              </div>
            </li>
          </ul>
        </ng-container>
      </div>

      <div *ngSwitchCase="'Query'" class="query">

        <header class="header">
          <button class="ms-Button ms-Button--command ms-CommandBarItem-link" (click)="back()" title="Back">
            <i class="ms-Icon ms-Icon--Back "></i>
          </button>

          <a class="ms-CommandBarItem-link" target="_blank" href="https://app.skyvia.com/#/query?id={{query.id}}"
            title="Open Query in Skyvia">
            <i class="ms-Icon ms-Icon--OpenInNewWindow"></i>
          </a>
        </header>

        <section class="body">

          <h4>{{query.name}}</h4>
          <p>{{query.description}}</p>

          <!-- <div class="ms-TextField">
            <label class="ms-Label">Workspace</label>
            <ng-select [items]="accounts" [(ngModel)]="query.workspaceId" (ngModelChange)="onRefreshConnections()" bindLabel="name" bindValue="id" groupBy="account"
              [clearable]="false" [searchable]="false">
            </ng-select>
          </div> -->

          <div class="ms-TextField">
            <label class="ms-Label">Connector</label>
            <ng-select
              #selectConnection
              placeholder="Select connection..."
              [(ngModel)]="query.connectionId"
              [items]="connectionsList | orderBy:'name'"
              bindLabel="name"
              bindValue="id"
              [loading]="loadingConnections"
              [clearable]="false"
              [searchable]="false"
              [searchFn]="customSearchFn">
              <ng-template ng-header-tmp>
                <div class="search-wrapper">
                  <i class="ms-Icon ms-Icon--Search"></i>
                  <input type="text" (input)="selectConnection.filter($event.target.value)" autofocus placeholder="Type to filter..."
                    class="ms-TextField-field" />
                </div>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <i class="icon-middle" [ngClass]="item.type"></i>
                <span class="text">{{item.name}}</span>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <i class="icon-middle" [ngClass]="item.type"></i>
                <span title="{{item.name}}">{{item.name}}</span>
              </ng-template>
              <ng-template ng-footer-tmp>
                <div class="btn-add-connection" (click)="selectConnection.close()">
                  <a href="{{host}}/#/{{query.workspaceId}}/connections/new" target="_blank">New</a>
                </div>
              </ng-template>
            </ng-select>
          </div>


        </section>

        <footer class="footer">
          <div class="button-bar">
            <button class="ms-Button run" (click)="run()" [disabled]="!query.connectionId" title="Run Query">
              <span>Run</span>
            </button>

            <button class="ms-Button edit" (click)="edit()" [disabled]="!query.connectionId" title="Edit Query">
              <span class="ms-Button-label">Edit</span>
            </button>
          </div>

          <div class="button-bar-right">
            <button class="ms-Button ms-CommandBarItem-link" (click)="delete()" title="Delete Query" [disabled]="query.sharingType">
              <i class="ms-Icon ms-Icon--Delete "></i>
            </button>
          </div>
        </footer>

      </div>
      <div *ngSwitchCase="'Settings'" class="query">
        <header class="header">
          <button class="ms-Button ms-Button--command ms-CommandBarItem-link" (click)="back()" title="Back">
            <i class="ms-Icon ms-Icon--Back "></i>
          </button>
        </header>
        <section class="body">

          <h4>Skyvia Add-in Settings</h4>

          <div class="ms-CheckBox" fabricCheckBox>
            <input class="ms-CheckBox-input" tabindex="-1" type="checkbox" id="positionAtCursor" [(ngModel)]="settings.positionAtCursor" (ngModelChange)="saveSettings()" [ngModelOptions]="{standalone: true}">
            <label role="checkbox" class="ms-CheckBox-field" for="positionAtCursor" title="Use current cursor position as the first cell of the table">
                <span class="ms-Label">Use current cursor position as the first cell of the table</span>
            </label>
          </div>

          <div class="ms-CheckBox" fabricCheckBox>
            <input class="ms-CheckBox-input" tabindex="-1" type="checkbox" id="renameIfSave" [(ngModel)]="settings.renameIfSave" (ngModelChange)="saveSettings()" [ngModelOptions]="{standalone: true}">
            <label role="checkbox" class="ms-CheckBox-field" for="renameIfSave" title="Assign query name to worksheet name">
                <span class="ms-Label">Assign query name to worksheet name</span>
            </label>
          </div>

          <div class="ms-CheckBox" fabricCheckBox>
            <input class="ms-CheckBox-input" tabindex="-1" type="checkbox" id="closeAfterExecute" [(ngModel)]="settings.closeAfterExecute" (ngModelChange)="saveSettings()" [ngModelOptions]="{standalone: true}">
            <label role="checkbox" class="ms-CheckBox-field" for="closeAfterExecute" title="Close the query window after execution">
                <span class="ms-Label">Close the query window after execution</span>
            </label>
          </div>
         </section> 

      </div>
    </div>
  </div>
</section>