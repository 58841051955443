<div class="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
    <div  class="ms-Dropdown operator">
        <ng-select [(ngModel)]="selectedObject.operator" [items]="operators" 
        [clearable]="false" bindValue="id" bindLabel="name" appendTo="body"></ng-select>
    </div>
</div>

<div class="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
    <div class="ms-Dropdown">
        <ng-select
            multiple="true"
            class="values-list"
            [clearable]="false"
            hideSelected="true"
            [(ngModel)]="selectedObject.$$listItems"
            (ngModelChange)="listItemsToObjValue()"
            [items]="valuesList"
            (scrollToEnd)="loadMore()"
            [loading]="loading"
            [dropdownPosition]="'bottom'">
            <ng-template ng-loading-tmp>
                <div class="spinner-wrapper"><div class="ms-Spinner" fabricSpinner></div></div>
            </ng-template>
        </ng-select>
    </div>
</div>