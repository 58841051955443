import { Directive, ElementRef, AfterViewInit } from '@angular/core';

import { Spinner } from '../../_fabric/Spinner';

@Directive({
  selector: '[fabricSpinner]'
})
export class SpinnerDirective implements AfterViewInit {

  private contextualMenu;

  constructor(private element: ElementRef) { }

  ngAfterViewInit() {
    this.contextualMenu = new Spinner(this.element.nativeElement);
  }

}
