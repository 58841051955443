import { Routes, RouterModule } from '@angular/router';

import { StartupComponent } from './startup/startup.component';
import { GalleryComponent } from './gallery/gallery.component';

import { QueryComponent } from './query/query.component';
import { AuthGuard } from './auth/guard';
import { CallbackComponent } from './auth/callback';
import { LoginComponent } from './auth/login';
import { LogoutComponent } from './auth/logout';
import { RefreshComponent } from './refresh/refresh.component';
import { NgModule } from '@angular/core';

const routes: Routes = [
    { path: 'startup', component: StartupComponent },
    { path: 'query', component: QueryComponent },
    { path: 'refresh', component: RefreshComponent },
    { path: '', component: GalleryComponent, canActivate: [AuthGuard] },
    { path: 'callback', component: CallbackComponent },
    { path: 'login', component: LoginComponent },
    { path: 'logout', component: LogoutComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
    providers: []
})
export class RoutingModule { }