import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[autofocus]'
})
export class AutofocusDirective {

    constructor(private el: ElementRef) {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            const input: HTMLInputElement = this.el.nativeElement as HTMLInputElement;
            input.focus();
            input.select();
        });
    }
}