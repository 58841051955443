import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { QueryService } from '../query.service';
import { QueryBuilderService } from './query-builder.service';
import { Utils } from 'app/services/util';
import { Toast, MessageBarType } from '../../_fabric/Toast';

@Component({
  selector: 'skyvia-query-builder',
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.scss']
})
export class QueryBuilderComponent implements OnInit, OnDestroy {

  @Input() connection;
  @Input() workspaceId;
  @Output() onIsDirty: EventEmitter<any> = new EventEmitter();

  private modelValue;
  @Output() modelChange = new EventEmitter();

  @Input()
  get model() {
    return this.modelValue;
  }

  set model(val) {
    this.modelValue = val;
    this.modelChange.emit(this.modelValue);
  }

  public tables;

  private toast;

  subscriptionRefreshObject;

  constructor(
    public queryService: QueryService,
    public queryBuilderService: QueryBuilderService,
    private utils: Utils) {

    this.toast = new Toast();
  }

  ngOnInit() {
    
    this.model.columns
      .map(c => this.queryBuilderService.getSourse(c.expression).source)
      .concat(
        this.flattenFilters(this.model.filter)
          .map((c) => this.queryBuilderService.getSourse(c.expression).source)
      ).every((source) => {

        const tableRef = this.model.sources.find((f) => f.relation == source);
        const table = this.utils.isDefined(tableRef) ? tableRef.table : source;

        this.initObject(table)
          .then(() => {
            if (this.tables.length != 0) {
              this.queryBuilderService.object = this.tables[0].name;
            }
          },()=>{});
      });

    if(!this.model.columns.length && this.queryBuilderService.object){
      this.initObject(this.queryBuilderService.object)
    }
  }

  ngOnDestroy() {

    if (this.subscriptionRefreshObject)
      this.subscriptionRefreshObject.unsubscribe();
  }

  setObject(name) {
    this.queryService.loading = true;
    this.model = this.queryBuilderService.createModel();

    this.initObject(name)
      .then(() => {
        this.selectAllColumns(this.tables[0], true)
        this.queryService.loading = false;

      })
    .catch(() => this.queryService.loading = false);
  }

  initObject(source) {

    this.tables = [];

    if(!this.connection) return Promise.reject();
    if(!this.workspaceId) return Promise.reject();

    return this.refreshObject(source)
      .then(
        object => {

        this.tables.push({
          name: source,
          columns: object.columns
        })

        object.parentRelations
          .forEach((parentRelation) => {

            if (!this.tables.find((f) => f.name == parentRelation.name)) {

              const item = object.columns.find((e) => e.name === parentRelation.column);
              this.tables.push({
                name: parentRelation.name,
                tableRef: this.queryBuilderService.createTableRef(object.alias, object.name, parentRelation, item.nullable),
                get: () => this.refreshObject(parentRelation.relatedTable)
              })
            }
          });

        this.onIsDirty.emit();
      },
        response => {

          const message = response?.error?.message ?? response?.message;
          this.toast.showToast(MessageBarType.error, message);
        });
  }

  initColumn(column, object) {

    setTimeout(() => {
      if (this.findModelColum(column, object)) {
        column.selected = true;
      } else {
        column.selected = false;
      }
      this.updateAllCheckboxState(object)
    });
  }

  initColumns(object) {

    return new Promise<void>((resolve, _) => {

      if (!object.columns) {
        if (object.get) {
          object.$$loading = true;
          object.showColumns = false;
          object.get().then((result) => {
            object.columns = result.columns;
            object.$$loading = false;
            object.showColumns = true;

            return resolve();
          });
        }
      } else {
        object.showColumns = false;
        setTimeout(_ => {
          object.showColumns = true;

          return resolve();
        })
      }
    });
  }

  selectAllColumns(table, selected) {

    if (table.columns) {
      table.columns.forEach(column => {
        if (column.selected != selected) {
          column.selected = selected;
          this.changeColumn(column, table);
        }
      });
    } else {
      this.initColumns(table).then(() => {
        this.selectAllColumns(table, selected);
      })
    }
  }

  changeColumn(column, table) {

    //del '*'
    this.model.columns = this.model.columns.filter(column => column.expression.column != '*');

    if (column.selected) { //add
      this.model.columns.push({ expression: this.queryBuilderService.createExpressionColumn(table.name, column.name, column.dataType) })
    } else {	//del
      this.model.columns = this.model.columns
        .filter(modelColumn => {
          return !this.utils.equals(modelColumn, this.findModelColum(column, table))
        });
    }

    if (table.tableRef) {
      this.addSources(table.tableRef);
    }

    setTimeout(() => this.updateAllCheckboxState(table));

    this.onIsDirty.emit();
  }

  initIndeterminate(table) {

    if (table.columns) {
      const columns =
        this.model.columns
          .filter(
            column => column.expression.source === table.name ||
              (column.expression.type === 'Function' && column.expression.values.some(value => value.source === table.name)));

      const selectedLength = columns.length;
      const allLength = table.columns.length;

      return selectedLength && allLength !== selectedLength;
    }
  }

  findModelColum(column, object) {
    return this.model.columns.find(modelColumn => {
      const source = this.queryBuilderService.getSourse(modelColumn.expression);
      return this.utils.equals(source.column, column.name) && this.utils.equals(source.source, object.name)
    });
  }

  addSources(tableRef) {

    if (!this.model.sources.some(s => this.utils.equals(s, tableRef))) {
      this.model.sources.push(tableRef);
    }

    this.recountSource();
  }

  recountSource() {

    const sources = [];

    const filterList = this.flattenFilters(this.model.filter);
    this.model.columns
      .concat(filterList.filter(f => f.type != "Group"))
      .concat(this.model.orderBy)
      .forEach(f => {

        const expression = this.queryBuilderService.getSourse(f.expression);
        const source = this.model.sources.find(f => f.relation == expression.source || f.label == expression.source);
        if (source) {

          if (!sources.some(s => s == source)) {
            sources.push(source);
          }

          //searchParentSource
          let parentName = source.source;
          while (parentName != undefined) {
            const parentSource = this.model.sources.find(f => f.relatedTable == parentName);
            if (parentSource) {
              parentName = parentSource.source;
              if (!sources.some(s => s == parentSource)) {
                sources.push(parentSource);
              }
            } else {
              parentName = undefined;
            }
          }

        }
      });

    this.model.sources = this.model.sources.filter(i => sources.some(s => this.utils.equals(s, i)));
  }

  lazyLoadColumns(object) {

    let check = () => {
      return this.model.columns.find(modelColumn => {
        const source = this.queryBuilderService.getSourse(modelColumn.expression);
        return this.utils.equals(source.source, object.name)
      });
    };

    if (check()) {
      this.initColumns(object);
    }

  }

  customSearchFn(filter) {
    if (filter) {
      filter = filter.toLowerCase();
    }
    return value =>
      !filter ||
      (value ? ('' + value.name).toLowerCase().indexOf(filter) !== -1 : false);
  }

  private updateAllCheckboxState(table) {

    if (!table.columns) return;

    for (var i = 0, count = 0, len = table.columns.length; i < len; i++) {
      const t = table.columns[i]
      if (t.selected)
        count++;
      else if (count > 0)
        break;
    }

    if (count === 0)
      table.$$allselected = false;
    else if (count === len)
      table.$$allselected = true;
    else
      table.$$allselected = null;
  }

  private refreshObject(source): Promise<any> {

    return new Promise((resolve, reject) => {
      this.subscriptionRefreshObject = this.queryService.refreshObject(this.connection, this.workspaceId, source)
        .subscribe(response => {
          resolve(response);
        }, response => {
          reject(response)
        })
    });

  }

  private flattenFilters(filters) {

    const flattenFilters = [];

    const flatten = (filter) => {

      if (filter.items != undefined)
        filter.items.forEach(function (element) {

          if (element.type == "Group")
            flatten(element)
          else
            flattenFilters.push(element);
        });
    }

    flatten(filters)
    return flattenFilters;
  }

  isLoading(): boolean {
    return this.queryService.loading;
  }

}