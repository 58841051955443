import { Injectable } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { DatatypesService } from '../../services/datatypes.service';

@Injectable()
export class ConditionService {

    getValueOperators(dataType, cantNull?) {

        let operators = [];

        if (dataType === 'Boolean' || dataType === 'GUID' || dataType === true)
            operators = operators.concat([

                { id: 'Equals', name: 'equal to' },
                { id: 'NotEquals', name: 'not equal to' }
            ]);

        if (dataType === 'Number' || dataType === 'Integer' || dataType === 'DateTime' || dataType === 'DateTimeOffset' || dataType === 'Date' || dataType === 'Money' || dataType === true)
            operators = operators.concat([

                { id: 'Equals', name: 'equal to' },
                { id: 'NotEquals', name: 'not equal to' },
                { id: 'LessThan', name: 'less than' },
                { id: 'LessOrEqual', name: 'less than or equal to' },
                { id: 'GreaterThan', name: 'greater than' },
                { id: 'GreaterOrEqual', name: 'greater than or equal to' },
                { id: 'Between', name: 'between' },
                { id: 'NotBetween', name: 'not between' }]);

        if (dataType === 'Text' || dataType === true)
            operators = operators.concat([

                { id: 'Equals', name: 'equal to' },
                { id: 'NotEquals', name: 'not equal to' },
                { id: 'Like', name: 'contains' },
                { id: 'NotLike', name: 'does not contain' },
                { id: 'StartsWith', name: 'starts with' },
                { id: 'NotStartsWith', name: 'does not start with' }
            ]);

        if (!cantNull)
            operators = operators.concat(
                { id: 'IsNull', name: 'is null' },
                { id: 'IsNotNull', name: 'is not null' });

        return operators;
    }


    getRelativeOperators() {

        let operators = [{ id: 'Is', name: 'is in' }, { id: 'NotIs', name: 'is not in' }];
        return operators;
    }


    getListOperators() {

        let conditions = [{ id: 'In', name: 'is in list' }, { id: 'NotIn', name: 'is not in list' }];
        return conditions;
    }
}

// @Pipe({ name: 'conditionOperatorName' })
// export class ConditionOperatorNamePipe implements PipeTransform {
//     constructor(private conditionSvc: ConditionService) { }
//     transform(id) {
//         if (!id)
//             return null;

//         let conditions = this.conditionSvc.getValueOperators(true);
//         conditions = conditions.concat(this.conditionSvc.getRelativeOperators());
//         conditions = conditions.concat(this.conditionSvc.getListOperators());
//         conditions = conditions.concat([
//             { id: 'And', name: 'All' },
//             { id: 'Or', name: 'Any' }]);

//         if (conditions.find(f => f.id === id))
//             return conditions.find(f => f.id === id).name;
//     }
// }

// @Pipe({ name: 'displayCondition' })
// export class DisplayConditionPipe implements PipeTransform {
//     constructor(private datatypesSvc: DatatypesService) { }
//     transform(argument, item) {
//         if (!argument)
//             return;

//         if (argument.type === 'Function' || argument.type === 'Parameter')

//             return argument.name;
//         else {
//             if (this.datatypesSvc.isDate(item.expression.dataType))
//                 return moment(argument.value).format('MM/DD/YYYY');

//             if (this.datatypesSvc.isDateTime(item.expression.dataType))
//                 return moment(argument.value).utc().format('MM/DD/YYYY HH:mm:ss');

//             if (this.datatypesSvc.isDateTimeOffset(item.expression.dataType))
//                 return moment(argument.value).utc().format('MM/DD/YYYY HH:mm:ss');


//             let decimalSeparator = (1.1).toLocaleString().substring(1, 2);
//             let value = argument.value;

//             if (typeof value === 'number')
//                 return value.toString().replace('.', decimalSeparator);


//             return argument.value;
//         }
//     }
// }
