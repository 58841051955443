import { Directive, OnInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[onCreate]'
})
export class OnCreateDirective implements OnInit {

    @Output() onCreate: EventEmitter<any> = new EventEmitter<any>();
 
    constructor(private element: ElementRef) { }

    ngOnInit() {
        this.onCreate.emit(this.element.nativeElement);
    }

}
