import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { forkJoin } from 'rxjs';
import { Toast, MessageBarType } from '../_fabric/Toast';
import { environment } from 'environments/environment';

import { GalleryService } from './gallery.service';
import { QueryService } from '../query/query.service';
import { DatatypesService } from '../services/datatypes.service';

import { QueryBuilderService } from 'app/query/query-builder/query-builder.service';
import { AppService } from 'app/app.service';
import { Utils } from 'app/services/util';
import { AuthSharedService } from 'app/auth/auth-shared.service';

import BigNumber from 'bignumber.js';
const JsonBigint = require('json-bigint');

@Component({
    templateUrl: 'gallery.component.html',
    styleUrls: ['gallery.component.scss']
})
export class GalleryComponent implements OnInit {
    
    title = 'gallery';

    host = environment.APP_ENDPOINT;

    workspaceIdDefault;
    accounts;

    loading: boolean;
    view: string;
    tab: string;
    filter: any;
    filterName: string;

    privateOnly: boolean;
    showQueries: boolean;

    queries: IQuery[];
    collections: object[];
    connectors: object[];
    connections: object[];
    query: any;

    loadingConnections: boolean;

    connectionsList: any[];

    navHistory: any[];

    datatypes: any;

    settings;

    userEmail;
    userName;

    private toast;

    constructor(
        private authService: AuthSharedService,
        private galleryService: GalleryService,
        private queryService: QueryService,
        private datatypesService: DatatypesService,
        private queryBuilderService: QueryBuilderService,
        private appService: AppService,
        private utils: Utils,
        private router: Router) {

        this.toast = new Toast();
    }

    ngOnInit() {

        const settings = localStorage.getItem('settings');
        if (settings) {
          this.settings = JSON.parse(settings);
        } else {
            this.settings = {
                positionAtCursor: false,
                renameIfSave: false,
                closeAfterExecute: false,
            };
        }

        this.privateOnly = false;
        this.navHistory = [];

        this.datatypes = this.datatypesService.userFriendlyDatatypes;

        this.getWorkspaces();
        this.getDefaultWorkspace();

        this.appService.initAppInsights();
        this.authService.setupAutomaticSilentRefresh();

        const claims: any = this.authService.getIdentityClaims();
        if (claims) {
            this.userEmail = claims.email;
            this.userName = claims.name;
        }
    }

    async getWorkspaces() {
        this.loading = true;
        try {
            const response = await this.appService.getWorkspaces().toPromise()

            let accounts = [];
            response.forEach((account) => {
                account.workspaces.forEach((workspace) => {
                    workspace['account'] = account.name;
                    accounts.push(workspace);
                });
            });
            this.accounts = accounts;

        } catch (response) {

            const message = response?.error?.message ?? response?.message;
            this.toast.showToast(MessageBarType.error, message);

        } finally {
            this.loading = false;
        }
    }

    async getDefaultWorkspace() {
        this.loading = true;
        try {
            
            const value = await this.appService.getDefaultWorkspace().toPromise()
            this.selectWorkspace(value);
            this.initQuery();

        } catch (response) {

            const message = response?.error?.message ?? response?.message;
            this.toast.showToast(MessageBarType.error, message);

        } finally {
            this.loading = false;
        }
    }

    selectWorkspace(id) {

        this.workspaceIdDefault = this.appService.setWorkspaceId(id);

        this.getData();
        this.groupByConnectors();  
    }

    initQuery = async () => {

        await Excel.run(async (context) => {

            let sheet = context.workbook.worksheets.getActiveWorksheet();

            await context.sync();

            let queryFind = this.findQuery(sheet)
            if (queryFind) {
                const query = queryFind.query; 
                if(query.id) {
                    this.getQueryFromServer(query)
                } else {
                    this.openQuery(queryFind.query);
                } 
            }

        });
    }

    selectSettings() {
        this.navigate('Settings', 'Settings')
    }

    saveSettings() {
        localStorage.setItem('settings', JSON.stringify(this.settings));
    }

    togglePrivate(value) {

        this.filter.privateOnly = this.privateOnly = value;

        this.getConnectors();
        this.getQueries();
    }

    allQueries() {
        this.clear();
        this.navigate('Queries', 'Queries');

        this.getQueries();
    }

    groupByConnections() {
        this.clear();
        this.navigate('Connections', 'Connections');
    }

    groupByConnectors() {
        this.clear();
        this.navigate('Sources', 'Sources');
    }

    getConnectors() {

        this.loading = true;

        this.galleryService.getConnectors(this.privateOnly, this.workspaceIdDefault)
            .subscribe(result => {
                this.connectors = result.filter(f=> f.name );
                
                this.loading = false;
            },(response: HttpErrorResponse) => {

                this.loading = false;

                const message = response?.error?.message ?? response?.message;
                this.toast.showToast(MessageBarType.error, message);
            })
    }

    filterQueries(name, filter) {

        this.filter = filter;
        this.filterName = name;
        this.getQueries();
        this.navigate('Queries', this.tab);
    }

    getQueries() {

        this.filter.privateOnly = this.privateOnly;

        this.loading = true;

        this.galleryService.getQueries(this.filter, this.workspaceIdDefault)
            .subscribe(result => {
                this.queries = result;
                
                this.loading = false;
            },(response: HttpErrorResponse) => {

                this.loading = false;

                const message = response?.error?.message ?? response?.message;
                this.toast.showToast(MessageBarType.error, message);
            })
    }

    getData() {

        this.loading = true;

        forkJoin([
            this.galleryService.getCollections(this.workspaceIdDefault),
            this.galleryService.getConnections(this.workspaceIdDefault),
            this.galleryService.getconnectionDefs(this.workspaceIdDefault)
        ]).subscribe(([collections, connections, connectionDefs]) => {

            this.collections = collections;
            this.connections = connections;

            this.galleryService.connectionDefs = {};
            connectionDefs.forEach(connection => {
                this.galleryService.connectionDefs[connection.providerId] = connection;
            });

            this.loading = false;
        },
        (response) => {

            this.loading = false;

            const message = response?.error?.message ?? response?.message;
            this.toast.showToast(MessageBarType.error, message);
        });

        this.getConnectors();
    }

    openQuery(query) {

        this.query = query;

        if (typeof this.query.parameters == 'string') {
            this.query.parameters = JSON.parse(this.query.parameters);
        }

        if (!this.query.contentType) {
            if (typeof this.query.content == 'string') {
                this.query.content = JsonBigint.parse(this.query.content, this.utils.jsonDateParser);
                this.query.content.filter = this.queryBuilderService.restoreConditions(this.query.content.filter);
            }
        }

        if(!this.query.workspaceId) { // NOTE: query without workspaceId
            this.query.workspaceId =  this.workspaceIdDefault;
        } else {
            this.workspaceIdDefault = this.query.workspaceId;
        }

        this.refreshConnections();

        this.navigate('Query', 'Query');
    }

    onRefreshConnections() {
        if(this.query.connectionId) {
          this.query.connectionId = null;
        }
        this.refreshConnections();
      }
   
    refreshConnections() {

        this.queryService.refreshConnections(this.query.workspaceId || this.workspaceIdDefault)
            .subscribe(
                (response: any) => {
                    this.connectionsList = response;
                },
                (response: HttpErrorResponse) => {

                    const message = response?.error?.message ?? response?.message;
                    this.toast.showToast(MessageBarType.error, message);
                })
    }

    getQueryFromServer(query) {

        this.galleryService.getQuery(query.id, query.workspaceId || this.workspaceIdDefault)
            .subscribe(result => {
                if (result) {
                    this.editGallary(result)
                    this.openQuery(result);
                }
            });
    }

    back() {
        this.navHistory.pop();

        let nav = this.navHistory[this.navHistory.length - 1];

        this.view = nav.view;
        this.tab = nav.tab;
        this.filterName = nav.filterName;
    }

    private navigate(view, tab) {

        this.navHistory.push({
            view: view,
            tab: tab,
            filterName: this.filterName
        });
        this.view = view;
        this.tab = tab;
    }

    private clear() {

        this.filter = {
            search: '',
            collection: '',
            connector: '',
            connection: ''
        }

        this.filterName = '';

        this.navHistory = [];
    }

    private editGallary(query) {

        if(!this.queries?.length) return;

        let indexQuery = this.queries.findIndex(f => f.id == query.id)
        if (indexQuery != -1)
            this.queries.splice(indexQuery, 1, query);
    }

    run() {
        if (this.loading) return;

        this.loading = true;

        this.execute(this.query)
            .then(response => {
                this.loading = false;
                this.toast.showToast(MessageBarType.success, 'The query was executed successfully.');

                this.appService.trackEvent(
                    'office-query-executed',
                    {
                        
                        queryId: this.query.id,
                        connectionId: this.query.connectionId,
                        id: this.query.connectionId, // TODO: delete
                        connector: this.connectionsList?.find(c=> c.id == this.query.connectionId).type,
                        type: this.query.contentType,
                        origin: 'gallary'
                    }
                )
            }, response => {

                this.loading = false;

                const message = response?.error?.message ?? response?.message;
                let promise;
                if (message == 'You have reached a limit of queries.') {
                    promise = this.queryService.getSubscriptions(this.query.workspaceId);
                } else {
                    promise = Promise.resolve(message);
                }
                promise.then(message => {
                    this.toast.showToast(MessageBarType.error, message);
                });

            });
    }

    execute(query, sheetId?) {

        return new Promise((resolve, reject) => {
            if (query.content)
                if (query.contentType) {
                    query.sql = query.content;
                } else {
                    query.model = query.content;
                }

                this.queryService.queryLongPolling(query) //this.queryService.query(query)
                .subscribe(
                    response => {
                        if (response.schema)
                            this.fill(response.result, response.schema, query, sheetId)
                                .then(_ => resolve(response)).catch(err => reject(err))
                        else
                            resolve(response)

                    },
                    (response: HttpErrorResponse) => {
                        reject(response);
                    });
        });
    }

    refreshAll() { // TODO: del

        let dialog//: Office.Dialog;

        let processMessage = () => {
            Excel.run(async (context) => {
                let worksheets = context.workbook.worksheets.load();
    
                    await context.sync();
    
                    for (var i = 0; i < worksheets.items.length; i++) {
    
                        let sheet = worksheets.items[i];
                        sheet.load("name");
                        // this.sheetRefresh(dialog, sheet);
                        const dialogWorkaround = new DialogRefreshWorkaround();
                        this.sheetRefresh(dialogWorkaround, sheet);

                    }
            });
        }

        let callback = (asyncResult) => {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                this.toast.showToast(MessageBarType.error, asyncResult.error.message);
            } else {
                dialog = asyncResult.value;
                dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
            }
        }

        if (this.loading) return;

        Office.context.ui.displayDialogAsync(`${environment.HOST}/#/refresh`, { height: 40, width: 35, displayInIframe: true }, callback);
    }

    refresh() {

        let dialog//: Office.Dialog;

        let processMessage = () => {
            Excel.run(async (context) => {
                let sheet =  context.workbook.worksheets.getActiveWorksheet(); 
                sheet.load("name");
                await context.sync();
    
                // this.sheetRefresh(dialog, sheet);
                const dialogWorkaround = new DialogRefreshWorkaround();
                this.sheetRefresh(dialogWorkaround, sheet);
            });
        }

        let callback = (asyncResult) => {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                this.toast.showToast(MessageBarType.error, asyncResult.error.message);
            } else {
                dialog = asyncResult.value;
                dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
            }
        }

        if (this.loading) return;

        Office.context.ui.displayDialogAsync(`${environment.HOST}/#/refresh`, { height: 40, width: 35, displayInIframe: true }, callback);
    }

    sheetRefresh(dialog, sheet) {

        let queryFind = this.findQuery(sheet);
        if (queryFind) {

            let messageToDialog = JSON.stringify(
                {
                    id: sheet.id,
                    name: sheet.name,
                    queryName: queryFind.query.name,
                    loading: true
                });
            dialog.messageChild(messageToDialog);

            this.execute(queryFind.query, queryFind.sheetId)
            .then(response => {

                let messageToDialog = JSON.stringify(
                    {
                        id: sheet.id,
                        name: sheet.name,
                        queryName: queryFind.query.name,
                        error: null,
                        loading: false
                    });
                dialog.messageChild(messageToDialog);

                this.appService.trackEvent(
                    'office-query-executed',
                    {
                        queryId: queryFind.query.id,
                        connectionId: queryFind.query.connectionId,
                        id: queryFind.query.connectionId, // TODO: delete
                        // connector: this.connectionsList.find(c=> c.id == queryFind.query.connectionId).type,
                        type: queryFind.query.contentType,
                        origin: 'refresh'
                    }
                )

            }, response => {

                const message = response?.error?.message ?? response?.message;
                let promise;
                if (message == 'You have reached a limit of queries.') {
                    promise = this.queryService.getSubscriptions(queryFind.query.workspaceId);
                } else {
                    promise = Promise.resolve(message);
                }
                promise.then(message => {
                   
                    let messageToDialog = JSON.stringify(
                        {
                            id: sheet.id,
                            name: sheet.name,
                            queryName: queryFind.query.name,
                            error: message,
                            loading: false
                        });
                    dialog.messageChild(messageToDialog);
                });
                
            });
        } else {

            let notFoundError = JSON.stringify({
                id: sheet.id,
                name: sheet.name,
                error: 'Query not fount',
                loading: false
            });
            dialog.messageChild(notFoundError);
        }
    }

    
    openQueryDialog(query) {

        if(query.content)
            if(query.contentType) {
                query.sql = query.content;
                query.model = this.queryBuilderService.createModel();
            } else {
                query.model = query.content;
                query.sql = "";
            }

        localStorage.setItem('query', JsonBigint.stringify(query));

        let dialog//: Office.Dialog;

        let processMessage = (arg) => {

            var messageFromDialog = JSON.parse(arg.message || null);

            if (messageFromDialog?.type == 'query-run') {
                this.execute(messageFromDialog.query)
                .then(response => {

                    let messageToDialog = JSON.stringify({
                        type: 'query-executed',
                        data: { type: MessageBarType.success, message: 'The query was executed successfully.'}
                    });
                    // dialog.messageChild(messageToDialog);
                    if (this.settings.closeAfterExecute) {
                        dialog.close();
                    }

                    localStorage.setItem('message', messageToDialog);

                    this.appService.trackEvent(
                        'office-query-executed',
                        {
                            queryId: messageFromDialog.query.id,
                            connectionId: messageFromDialog.query.connectionId,
                            id: messageFromDialog.query.connectionId, // TODO: delete
                            connector: this.connectionsList?.find(c=> c.id == this.query.connectionId).type,
                            type: messageFromDialog.query.contentType,
                            origin: 'query'
                        }
                    )

                }, response => {


                    const message = response?.error?.message ?? response?.message;
                    let promise;
                    if (message == 'You have reached a limit of queries.') {
                        promise = this.queryService.getSubscriptions(messageFromDialog.query.workspaceId);
                    } else {
                        promise = Promise.resolve(message);
                    }
                    promise.then(message => {

                        let messageToDialog = JSON.stringify({
                            type: 'query-executed',
                            data: { type: MessageBarType.error, message: message}
                        });
                        // dialog.messageChild(messageToDialog);
                        localStorage.setItem('message', messageToDialog);

                    });
                });
            }

            if (messageFromDialog?.type == 'query-saved') {
                
                this.getData();

                if (this.query?.id) {
                    this.getQueryFromServer(this.query);
                }

                if (this.settings.renameIfSave) {
                    this.renameWorksheet(null, messageFromDialog.query.name);
                }
            }

        }

        let callback = (asyncResult) => {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                this.toast.showToast(MessageBarType.error, asyncResult.error.message);
            } else {
                dialog = asyncResult.value;
                dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
            }
        }

        Office.context.ui.displayDialogAsync(`${environment.HOST}/#/query`, { height: 80, width: 70, displayInIframe: true }, callback);
    }

    newQuery = async () => {

        if (this.loading) return;

        await Excel.run(async (context) => {

            let sheet = context.workbook.worksheets.getActiveWorksheet();

            await context.sync();

            let query: IQuery = {
                contentType: 0,
                parameters: [],
                connectionId: null,
                sql: '',
                model: this.queryBuilderService.createModel(),
            };

            let queryFind = this.findQuery(sheet)
            if (queryFind)
                query = queryFind.query

            this.openQueryDialog(query);
        })
        .catch(err => {
            this.toast.showToast(MessageBarType.error, err.message);
         })
    }

    edit() {
        if (this.loading) return;

        this.openQueryDialog(this.query)
    }

    delete() {
        if (this.loading) return;

        this.galleryService.deleteQuery(this.query)
            .then(() => {
                this.back();
                this.getData();
                this.getQueries();
            },
            (response) => {
                this.toast.showToast(MessageBarType.error, response.message);
            });
    }

    logout() {

        let dialog;

        let processMessage = (arg) => {

            var messageFromDialog = JSON.parse(arg.message);
            if (messageFromDialog.messageType === "logoutSuccess") {

                this.loading = true;
                setTimeout(_ => {
                    this.router.navigate(['startup']);
                }, 2000)

            }

        }

        let callback = (asyncResult) => {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                this.toast.showToast(MessageBarType.error, asyncResult.error.message);
           } else {
            dialog = asyncResult.value;
            dialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
          }
        }

        Office.context.ui.displayDialogAsync(`${environment.HOST}/#/logout`, {height: 55, width: 35, }, callback)
    }

    customSearchFn(term: string, item) {
        term = term.trim().toLowerCase();
        return item.name.toLowerCase().indexOf(term) > -1 || item.type.toLowerCase().indexOf(term) > -1;
    }

    customSearch(filter) {
        if (filter) {
            filter = filter.toLowerCase();
        }
        return value =>
            !filter ||
            (value ? ('' + value.name).toLowerCase().indexOf(filter) !== -1 : false);
    }

    private checkSelectStatement(sql) {
        return sql.slice(0, 6).toLowerCase() == 'select';
    }

    private saveMeta = async (query, sheetId, range) => {

        var _settings = Office.context.document.settings;

        let metaData = _settings.get('worksheetsMetaData')
        let queries = JsonBigint.parse(metaData) || [];
        let data = { query: query, sheetId: sheetId, range: range };

        let indexQuery = queries.findIndex(f => f.sheetId == sheetId)
        if (indexQuery != -1)
            queries.splice(indexQuery, 1, data);
        else
            queries.push(data)

        _settings.set('worksheetsMetaData', JsonBigint.stringify(queries));

        try {
            await _settings.saveAsync(function (c) { })
        }
        catch (err) {
           return err;
        }
    }

    private findQuery(sheet) {

        var _settings = Office.context.document.settings;

        let metaData = _settings.get('worksheetsMetaData')
        let queries = JsonBigint.parse(metaData) || [];

        return queries.find(f => f.sheetId == sheet.id)
    }

    private fill = async (values, columns, query, sheetId?) => {

        let localizedValue = (value) =>{
            if (value) {
                const t = typeof value;
                if (t === 'number') {
                    return value.toString();
                } else if (BigNumber.isBigNumber(value)) {
                    return value.toString();
                } 
            }
            return value;
        }

        let  objectValues = (object)=> {
            return Object.keys(object).map(k=> {
                const value = localizedValue(object[k]);
               return value;
            });
        }

        values = values.map(value => objectValues(value));
        if (columns.length) {
            values.unshift(columns.map(c => c.name));
        }

        await Excel.run(async (context) => {

            let sheet = sheetId ? context.workbook.worksheets.getItem(sheetId) : context.workbook.worksheets.getActiveWorksheet();

            await context.sync();

            // let oldQuery = this.findQuery(sheet);
            // if (oldQuery && oldQuery.range) {
            //     sheet.getRange(oldQuery.range).clear();
            // }
            sheet.getRange().clear();

            let start = this.settings.positionAtCursor ? await this.selectedCell(context) : { row:  0, column:  0, a1Notation: 'A1' }; // TODO: get start position if refresh
            let end = await this.getAddress(context, sheet, values.length - 1 + start.row, columns.length - 1 + start.column);

            let address = start.a1Notation + ":" + end;
            let range = sheet.getRange(address);
            range.values = values;

            await this.saveMeta(query, sheet.id, address)
        })
        .catch(err => {
           throw new Error( err)
        })

    }

    private renameWorksheet = async (sheetId, name ) => {
        await Excel.run(async (context) => {

            let sheet = sheetId ? context.workbook.worksheets.getItem(sheetId) : context.workbook.worksheets.getActiveWorksheet();
            sheet.name = name;

            await context.sync();

        })
        .catch(err => {
           throw new Error( err)
        })
    }

    private getAddress = async (context, sheet, rows, columns) => {

        let cell = sheet.getCell(rows, columns);
        cell.load('address');

        try {
            await context.sync();
            return cell.address.split('!')[1];
        }
        catch (err) {
            this.toast.showToast(MessageBarType.error, err.message);
        }

    }

    private selectedCell = async (context) => {
        var cell = context.workbook.getSelectedRange().load();
        cell.load('rowIndex');
        cell.load('columnIndex');
        cell.load('address');

        try {
            await context.sync();
            return { row: cell.rowIndex || 0, column: cell.columnIndex || 0, a1Notation: cell.address.split('!')[1] || 'A1' };
        }
        catch (err) {
            this.toast.showToast(MessageBarType.error, err.message);
        }
    }


}


class DialogRefreshWorkaround {

    messageChild(message) {

        let items = JsonBigint.parse(localStorage.getItem('refresh'));
        if (!items)  {
            items = [];
        }

        const value = JSON.parse(message);
        const index = items.findIndex(s => s.id === value.id);
        if(index != -1) {
            items[index] = value;
        } else {
            items.push(value);    
        }

        localStorage.setItem('refresh', JsonBigint.stringify(items));
    }


}