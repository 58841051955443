import { Component, inject } from '@angular/core';
import { AuthSharedService } from './auth/auth-shared.service';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent {

    constructor(private authService: AuthSharedService) {

    }

    ngOnInit() {
        this.authService.setupAutomaticSilentRefresh();
    }

//   constructor(private oauthService: OAuthService, private router: Router, private appService: AppService ) {

//     this.oauthService.configure(authConfig);

//     this.oauthService.loadDiscoveryDocument().then(()=>{
//       this.oauthService.tryLogin();
//     });
  
    // if (window.addEventListener) {                                          
    //   window.addEventListener('storage', this.handle_storage.bind(this), false);                      
    // } else {                        
    //   (<any>window).attachEvent('onstorage', this.handle_storage.bind(this));
    // }
//   }

  // handle_storage(e) {

  //   if (this.router.url.startsWith('/callback') ) {return;}

  //   if (e.key == 'expires_at') {

  //     if (this.oauthService.hasValidAccessToken()) {

  //       const url = this.appService.getRedirectUrl() || '/';
  //       this.appService.setRedirectUrl(null); //NOTE: clear

  //       this.router.navigate([url]);
  //     } else {
  //       this.router.navigate(['/startup']);
  //     }
  //   }

  // }
}
