import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from 'environments/environment';

export const authConfig: AuthConfig = {
    issuer: `${environment.AUTH_ENDPOINT}/core`,
    redirectUri:  window.location.origin + "/#/callback",
    userinfoEndpoint:  `${environment.AUTH_ENDPOINT}/core/connect/userinfo`,
    tokenEndpoint:  `${environment.AUTH_ENDPOINT}/core/connect/token`,
    clientId: 'excelapp',
    scope: 'openid offline_access',
    dummyClientSecret: 'secret',
    responseType: 'code',
    disablePKCE: true,
    postLogoutRedirectUri: `${environment.AUTH_ENDPOINT}/core/connect/endsession`,
    disableAtHashCheck: true
  }
