import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { QueryBuilderService } from 'app/query/query-builder/query-builder.service';
import { ConditionService } from 'app/query/query-builder/condition.service';

@Component({
    selector: 'condition-relative',
    templateUrl: './condition-relative.component.html',
    styleUrls: ['./condition-relative.component.scss']
})
export class ConditionRelativeComponent implements OnChanges {

    @Input() selectedObject;
    @Output() isDirty: EventEmitter<any> = new EventEmitter();

    public operators: any[];
    public relativeList: any[];

    constructor(
        private queryBuilderSvc: QueryBuilderService,
        private conditionSvc: ConditionService,
    ) {

        this.operators = this.conditionSvc.getRelativeOperators();
        this.relativeList = this.getRelative();
    }

    ngOnChanges(changes) {

        if (changes.selectedObject.currentValue !== changes.selectedObject.previousValue)
            this.selectedObjectChanged(changes.selectedObject.currentValue);
    }

    selectedObjectChanged(value) {

        if (!this.operators.some(f => { return f.id == value.operator }))
            value.operator = this.operators[0].id;

        this.relativeToValues();
    }

    relativeToValues() {

        this.selectedObject.arguments = [];

        this.selectedObject.arguments.push(this.queryBuilderSvc.createExpressionFunction(this.selectedObject.$$relative, null, null, null));

        this.isDirty.emit();
    }

    private getRelative() {

        var items = [
            { id: 'Yesterday', name: 'Yesterday' },
            { id: 'Today', name: 'Today' },
            { id: 'Tomorrow', name: 'Tomorrow' },
            { id: 'PreviousMonth', name: 'Previous Month' },
            { id: 'CurrentMonth', name: 'Current Month' },
            { id: 'NextMonth', name: 'Next Month' },
            { id: 'PreviousYear', name: 'Previous Year' },
            { id: 'CurrentYear', name: 'Current Year' },
            { id: 'NextYear', name: 'Next Year' }
        ];
        return items;
    }

}
