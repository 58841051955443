import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { authConfig } from './authConfig';
import { AuthConfig, OAuthModule, OAuthStorage, OAuthSuccessEvent } from 'angular-oauth2-oidc';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
    return localStorage;
}

export function authAppInitializerFactory(authService: AuthService): () => Promise<OAuthSuccessEvent> {
    return () => authService.loadDiscoveryDocument()
}

@NgModule({
    imports: [
        CommonModule,
        OAuthModule.forRoot(),
    ],
    providers: [
        AuthService,
    ]
})
export class AuthModule {
    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
          ngModule: AuthModule,
          providers: [
            { provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthService], multi: true },
            { provide: AuthConfig, useValue: authConfig },
            { provide: OAuthStorage, useFactory: storageFactory },
          ]
        };
      }
    
      constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
        if (parentModule) {
          throw new Error('AuthModule is already loaded. Import it in the AppModule only');
        }
      }
}
