import { Directive, ElementRef, OnInit, AfterViewChecked } from '@angular/core';

import { Dropdown } from '../../_fabric/Dropdown';

@Directive({
  selector: '[fabricDropdown]'
})
export class DropdownDirective implements OnInit, AfterViewChecked {

  private dropdown;
  private needInit;

  constructor(private element: ElementRef) {
  }

  ngOnInit() {
    this.needInit = true;
  }

  ngAfterViewChecked() {

    if (!this.needInit)
      return;

    let list = this.element.nativeElement.querySelectorAll('select>option:not([hidden])');
    if (list.length) {

      this.needInit = false;

      setTimeout(_=> {
        this.dropdown = new Dropdown(this.element.nativeElement);
      });
    }
    //this.renderer.invokeElementMethod(this.element.nativeElement, 'focus', []);
  }

}
