<div class="flex">
    <ng-select [(ngModel)]="item.operator" [ngModelOptions]="{standalone: true}" [items]="groupTypes" [clearable]="false" [searchable]="false">
    </ng-select>

    <div>
        <button type="button" (click)="addCondition()" title="Add Filter Condition" class="ms-Button ms-CommandBarItem-link">
            <span class="flex-container">
                <i class="ms-Icon ms-Icon--Add"></i>
                <span>Condition</span>
              </span>
        </button>
        <button type="button" (click)="addGroup()" title="Add Filter Group" class="ms-Button ms-CommandBarItem-link">
            <span class="flex-container">
                <i class="ms-Icon ms-Icon--Add"></i>
                <span>Group</span>
              </span>
        </button>
        <button type="button" (click)="remove()" *ngIf="parentItem" title="Remove Group" class="ms-Button ms-CommandBarItem-link remove">
            <span class="flex-container">
                <i class="ms-Icon ms-Icon--Delete"></i>
            </span>
        </button>
    </div>
</div>

<div *ngFor="let childItem of item.items; let i  = index">
    <ng-container *ngIf="showChildren">
        <ui-filter-item [index]="i"
                    [item]="childItem"
                    [parentItem]="item"
                    [parentIndex]="parentIndex + '_' + i"
                    [tableRefs]="tableRefs"
                    [showErrors]="showErrors"
                    [showVariables]="showVariables"
                    [showLastRunVariable]="showLastRunVariable"
                    [connection]="connection"
                    [workspaceId]="workspaceId"
                    (initChildren)="initChildren()"
                    (addSources)="addSources.emit($event)"
                    (recountSource)="recountSource.emit($event)"
                    (onIsDirty)="onIsDirty.emit($event)">
        </ui-filter-item>
    </ng-container>
</div>
