import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard  {

    constructor(private router: Router, private authService: AuthService) { }

    canActivate() {

        return this.authService
        .getToken()
        .pipe(
            map((e) => {
                if (e) {
                    return true;
                }
            }),
            catchError(() => {
                this.router.navigate(['/startup']);
                return of(false);
            })
        )


        // return new Promise<boolean>((resolve) => {

        //     if (this.oauthService.hasValidAccessToken()) {
        //         return resolve(true)
        //     }

        //     this.oauthService.refreshToken().then(_ => {

        //         if (this.oauthService.hasValidAccessToken()) {
        //             return resolve(true)
        //         }

        //         this.router.navigate(['/startup']);
        //         return resolve(false);

        //     })



        // });
    }
}