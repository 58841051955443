<div class="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
    <div  class="ms-Dropdown operator">
        <ng-select [(ngModel)]="selectedObject.operator" (ngModelChange)="setOperator()" [items]="operators" 
        [clearable]="false" bindValue="id" bindLabel="name" appendTo="body"></ng-select>
    </div>
</div>
<div class="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
    <div *ngIf="notBetween()" >   
        <value-selector [(ngModel)]="selectedObject.$$values[0]" [name]="index" (ngModelChange)="valuesToArguments()" [dataType]="selectedObject.expression.dataType" [showErrors]="true" *ngIf="needEditor()"></value-selector>
    </div>
    <div *ngIf="!notBetween()"  class="between">
        <value-selector [(ngModel)]="selectedObject.$$values[0]"  name="{{index+'_0'}}" (ngModelChange)="valuesToArguments()" [dataType]="selectedObject.expression.dataType" [showErrors]="true"></value-selector>
        <value-selector [(ngModel)]="selectedObject.$$values[1]" name="{{index+'_1'}}" (ngModelChange)="valuesToArguments()" [dataType]="selectedObject.expression.dataType" [showErrors]="true" ></value-selector>
    </div>
</div>