import { Directive, ElementRef, Input, OnInit, AfterViewChecked, OnChanges, SimpleChanges } from '@angular/core';

import { CheckBox } from '../../_fabric/CheckBox';

@Directive({
  selector: '[fabricCheckBox]'
})
export class CheckBoxDirective implements OnInit,OnChanges, AfterViewChecked {

  checkbox;
  needInit

  @Input() fabricCheckBox;
  @Input() indeterminate;

  constructor(private element: ElementRef) {
  }

  ngOnInit() {
    this.needInit = true;
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.checkbox) {

      if (changes.fabricCheckBox && !changes.fabricCheckBox.firstChange) {
        if (changes.fabricCheckBox.currentValue) {
            this.checkbox.check();
        } else {
            this.checkbox.unCheck();
        }
      }

      if (changes.indeterminate && !changes.indeterminate.firstChange) {
        if (changes.indeterminate.currentValue) {
            this.checkbox.indeterminateCheck();
        } else {
            this.checkbox.indeterminateUnCheck();
        }
      }
    }

  }

  ngAfterViewChecked() {

    if (!this.needInit)
      return;

      this.needInit = false;

      setTimeout(_=> {
        this.checkbox = new CheckBox(this.element.nativeElement);
      });
  
  }

}
