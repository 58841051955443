<div [ngClass]="{'first': !parentItem}">
<ng-container [ngSwitch]="item.type">
    <ng-container *ngSwitchCase="'Condition'">
        <filter-condition 
            [item]="item" 
            [index]="index" 
            [tableRefs]="tableRefs" 
            [parentItem]="parentItem"
            [parentIndex]="parentIndex" 
            [showErrors]="showErrors" 
            [showVariables]="showVariables"
            [showLastRunVariable]="showLastRunVariable"
            [connection]="connection"
            [workspaceId]="workspaceId"
            (initChildren)="initChildren.emit()"
            (addSources)="addSources.emit($event)"
            (recountSource)="recountSource.emit($event)"
            (onIsDirty)="onIsDirty.emit($event)">
        </filter-condition>
    </ng-container>
    <ng-container *ngSwitchCase="'Group'">
        <filter-group 
            [item]="item" 
            [index]="index" 
            [tableRefs]="tableRefs" 
            [parentItem]="parentItem"
            [parentIndex]="parentIndex" 
            [showErrors]="showErrors" 
            [showVariables]="showVariables"
            [showLastRunVariable]="showLastRunVariable"
            [connection]="connection"
            [workspaceId]="workspaceId"
            (addSources)="addSources.emit($event)"
            (recountSource)="recountSource.emit($event)"
            (onIsDirty)="onIsDirty.emit($event)">
        </filter-group>
    </ng-container>
</ng-container>
</div>