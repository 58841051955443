import { Component, AfterViewInit, inject } from '@angular/core';
import { AuthSharedService } from './auth-shared.service';

@Component({
  selector: 'login',
  template: `Please wait while we are redirecting you to the auth server.`
})
export class LoginComponent implements AfterViewInit {

  constructor(private authService:AuthSharedService ) {

  }

  ngAfterViewInit() {
    this.authService.login();
  }

}
