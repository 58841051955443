import { Injectable } from '@angular/core';

@Injectable()
export class DatatypesService {


  public datatypes;
  public userFriendlyDatatypes;

  constructor() {


    this.datatypes = {
      DT_AUTO: 0,
      DT_BYTES: 1,
      DT_BOOL: 2,
      DT_DBDATE: 3,
      DT_DBTIMESTAMP: 4,
      DT_DECIMAL: 5,
      DT_R8: 6,
      DT_GUID: 7,
      DT_I1: 8,
      DT_I2: 9,
      DT_I4: 10,
      DT_I8: 11,
      DT_R4: 12,
      DT_WSTR: 13,
      DT_DBTIME: 14,
      DT_UI1: 15,
      DT_UI2: 16,
      DT_UI4: 17,
      DT_UI8: 18,
      DT_CY: 19,
      DT_DATE: 20,
      DT_FILETIME: 21,
      DT_STR: 22,
      DT_NUMERIC: 23,
      DT_DBTIME2: 24,
      DT_DBTIMESTAMPOFFSET: 25,
      DT_IMAGE: 26,
      DT_TEXT: 27,
      DT_NTEXT: 28,
      DT_DBTIMESTAMP2: 29
    };

    this.userFriendlyDatatypes = {
      'Boolean': this.datatypes.DT_BOOL,
      'Date': this.datatypes.DT_DBDATE,
      'DateTime': this.datatypes.DT_DBTIMESTAMP,
      'Number': this.datatypes.DT_R8,
      'Text': this.datatypes.DT_WSTR,
     
    };

  }

  getUserFriendlyTypeName(dataType) {

    switch (dataType) {
      case this.datatypes.DT_I1:
      case this.datatypes.DT_I2:
      case this.datatypes.DT_I4:
      case this.datatypes.DT_I8:
      case this.datatypes.DT_UI1:
      case this.datatypes.DT_UI2:
      case this.datatypes.DT_UI4:
      case this.datatypes.DT_UI8:
        return 'Integer';

      case this.datatypes.DT_CY:
        return 'Money';

      case this.datatypes.DT_DECIMAL:
      case this.datatypes.DT_NUMERIC:
      case this.datatypes.DT_R4:
      case this.datatypes.DT_R8:
        return 'Number';

      case this.datatypes.DT_STR:
      case this.datatypes.DT_WSTR:
      case this.datatypes.DT_TEXT:
      case this.datatypes.DT_NTEXT:
        return 'Text';

      case this.datatypes.DT_DBDATE:
        return 'Date';

      case this.datatypes.DT_DBTIMESTAMPOFFSET:
        return 'DateTimeOffset';

      case this.datatypes.DT_DBTIME:
        return 'Time';


      case this.datatypes.DT_DBTIMESTAMP:
      case this.datatypes.DT_DBTIMESTAMP2:
        return 'DateTime';

      case this.datatypes.DT_BOOL:
        return 'Boolean';

      case this.datatypes.DT_GUID:
        return 'GUID';

      case this.datatypes.DT_BYTES:
      case this.datatypes.DT_IMAGE:
        return 'Bytes';
    }
  }

  isValidGuid(value) {
    let regex = /^\{[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\}$/i;
    return regex.test(value);
  }

  
  isDate(dt) {
    return dt === this.datatypes.DT_DBDATE;
  }

  isDateTime(dt) {
    return dt === this.datatypes.DT_DATE || dt === this.datatypes.DT_DBTIMESTAMP || dt === this.datatypes.DT_DBTIMESTAMP2;
  }

  isDateTimeOffset(dt) {
    return dt === this.datatypes.DT_DBTIMESTAMPOFFSET;
  }

}