import { Injectable, Inject, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { environment } from 'environments/environment';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

    handleError(error) {

        if (error instanceof HttpErrorResponse) {
            throw error;
        } else {
            if (environment.production) {
                // ApplicationInsights.trackException(error, null, { message: error.message ? error.message : error.toString() });
            } else {
                throw error;
            };
        }
    }
}