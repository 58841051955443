import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FilterItemService } from './filter-item.service';

@Component({
    selector: 'ui-filter-item',
    templateUrl: './filter-item.component.html',
    styleUrls: ['./filter-item.component.scss']
})
export class FilterItemComponent {

    @Input() index;
    @Input() tableRefs;
    @Input() item;
    @Input() parentItem;
    @Input() parentIndex;

    @Input() showErrors;
    @Input() showVariables;
    @Input() showLastRunVariable;

    @Input() connection;
    @Input() workspaceId;

    @Output() initChildren  = new EventEmitter<any>();

    @Output() addSources: EventEmitter<any> = new EventEmitter<any>();
    @Output() recountSource: EventEmitter<any> = new EventEmitter<any>();

    @Output() onIsDirty: EventEmitter<any> = new EventEmitter();
}

