<div class="modal-header">
  <h4>Refresh</h4>
</div>

<div class="modal-body">
  <ul class="queries-list">
    <li *ngFor="let sheet of sheets" class="query">
        <div class="icon-status">
          <i *ngIf="sheet.loading" class="loading spin ms-Icon  ms-Icon--Refresh"></i>
          <i *ngIf="sheet.error" class="error ms-Icon  ms-Icon--ChromeClose"></i>
          <i *ngIf="!sheet.loading && !sheet.error" class="successful ms-Icon ms-Icon--Accept"></i>
        </div>
        <div class="message">
          <div class="name">{{sheet.name}}</div>
          <div class="description" *ngIf="sheet.error">{{sheet.error}}</div>
          <div class="description" *ngIf="sheet.queryName">{{sheet.queryName}}</div>
        </div>
    </li>
  </ul>
</div>
