import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { QueryBuilderService } from 'app/query/query-builder/query-builder.service';
import { DatatypesService } from 'app/services/datatypes.service';
import { ConditionService } from 'app/query/query-builder/condition.service';
import { Utils } from 'app/services/util';



@Component({
    selector: 'condition-value',
    templateUrl: './condition-value.component.html',
    styleUrls: ['./condition-value.component.scss']
})
export class ConditionValueComponent implements OnChanges {

    @Input() selectedObject;
    @Input() index;
    @Output() isDirty: EventEmitter<any> = new EventEmitter();

    operators: any[];
    dataType: string;

    constructor(
        private queryBuilderSvc: QueryBuilderService,
        private datatypesSvc: DatatypesService,
        private conditionSvc: ConditionService,
        private utils: Utils
    ) { }

    ngOnChanges(changes) {

        if (changes.selectedObject.currentValue !== changes.selectedObject.previousValue)
            this.selectedObjectChanged(changes.selectedObject.currentValue);
    }

    selectedObjectChanged(value) {

        this.dataType = this.datatypesSvc.getUserFriendlyTypeName(value.expression.dataType);

        if (!value.$$values) {
            value.$$values = [];

            if (this.dataType == 'Text')    //#64290
                value.$$values.push('');
        }

        this.operators = this.conditionSvc.getValueOperators(this.dataType);

        if (!this.operators.some(f => { return f.id == value.operator }))
            value.operator = this.operators[0].id;

        this.valuesToArguments();
    }

    valuesToArguments() {

        this.selectedObject.arguments = [];

        if (this.utils.isArray(this.selectedObject.$$values))
            this.selectedObject.$$values.forEach(el => {
                if (!(this.utils.isUndefined(el) || el === null) && !(this.dataType != 'Text' && el.length == 0))
                    this.selectedObject.arguments.push(this.queryBuilderSvc.createExpressionConstant(el));
            });

        this.isDirty.emit();
    }

    needEditor(): boolean {
        if (this.selectedObject.operator != null)
            return !(this.selectedObject.operator === 'IsNull' || this.selectedObject.operator === 'IsNotNull');
    }

    notBetween() {
        if (this.selectedObject.operator != null)
            return !(this.selectedObject.operator === 'Between' || this.selectedObject.operator === 'NotBetween');
    }

    setOperator() {

        if (!this.needEditor()) {
            this.selectedObject.$$values = [];

            if (this.dataType == 'Text')    //#64290
                this.selectedObject.$$values.push('');
        }

        if (this.notBetween())
            if (this.selectedObject.$$values && this.selectedObject.$$values.length) {
                var first = this.selectedObject.$$values.shift();
                this.selectedObject.$$values = [];
                this.selectedObject.$$values.push(first);
            }

        this.valuesToArguments();
    }

}
