import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FilterItemService } from '../filter-item.service';
import { QueryBuilderService } from 'app/query/query-builder/query-builder.service';

@Component({
    selector: 'filter-group',
    templateUrl: './filter-group.component.html',
    styleUrls: ['./filter-group.component.scss'],
})
export class FilterGroupComponent {

    @Input() index;
    @Input() tableRefs;
    @Input() item;
    @Input() parentItem;
    @Input() parentIndex;
    @Input() showErrors;
    @Input() showVariables;
    @Input() showLastRunVariable;

    @Input() connection;
    @Input() workspaceId;

    @Output() addSources: EventEmitter<any> = new EventEmitter<any>();
    @Output() recountSource: EventEmitter<any> = new EventEmitter<any>();

    @Output() onIsDirty: EventEmitter<any> = new EventEmitter();

    groupTypes = ['And', 'Or'];

    showChildren = true;

    constructor(private queryBuilderSvc: QueryBuilderService) { }

    addGroup() {
        this.item.items.push(this.queryBuilderSvc.createFilterGroup());
    }

    addCondition() {
        this.item.items.push(this.queryBuilderSvc.createFilterCondition());
        this.onIsDirty.emit();
    }

    remove() {

        if (this.parentItem) {
            const index = this.parentItem.items.indexOf(this.item);
            this.parentItem.items.splice(index, 1);
            this.recountSource.emit();
            this.onIsDirty.emit();
        }
    }

    initChildren() {
        this.showChildren = false;
        setTimeout(() => {
            this.showChildren = true;
        }, 16);
    }

}
