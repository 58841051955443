<div class="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
    <div  class="ms-Dropdown operator">
        <ng-select [(ngModel)]="selectedObject.operator" [items]="operators" 
        [clearable]="false" bindValue="id" bindLabel="name" appendTo="body"></ng-select>
    </div>
</div>
<div class="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
    <div class="ms-Dropdown arguments">
        <ng-select [(ngModel)]="selectedObject.$$relative" (ngModelChange)="relativeToValues()" [items]="relativeList" 
        [clearable]="false" bindValue="id" bindLabel="name" appendTo="body"></ng-select>
    </div>
</div>