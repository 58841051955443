import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from './util';

import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

@Pipe({ name: 'utcDate' })
export class UtcDate implements PipeTransform {

    constructor(private utils: Utils) { }

    transform(value) {

        if (!value) {
            return value;
        }

        if (!this.utils.isValidDate(value, false)) {
            return value;
        }

       return dayjs(value).utc().format('MM/DD/YYYY HH:mm:ss');
    }
}

@Pipe({ name: 'dateOnly' })
export class DateOnly implements PipeTransform {

    constructor(private utils: Utils) { }

    transform(value) {

        if (!value) {
            return value;
        }

        if (!this.utils.isValidDate(value, false)) {
            return value;
        }

        return dayjs(value).format('MM/DD/YYYY');
    }
}