<div class="spinner-wrapper" *ngIf="isLoading()">
    <div class="ms-Spinner ms-Spinner--large" fabricSpinner></div>
</div>

<div [ngSwitch]="view" *ngIf="workspaceIdDefault">
    <!-- <div *ngSwitchDefault>
        <div class="ms-Spinner ms-Spinner--large" fabricSpinner>
            <div class="ms-Spinner-label">
                Loading Query Component...
            </div>
        </div>
    </div> -->
    <div class="inline" *ngSwitchCase="'query'">

        <div class="modal-header">
            <div class="ms-TextField">

                <ng-select [items]="accounts" [(ngModel)]="query.workspaceId" (ngModelChange)="onRefreshConnections()" bindLabel="name" bindValue="id" groupBy="account"
                    [clearable]="false" [searchable]="false">
                    <ng-template ng-label-tmp let-item="item">
                        <span class="text" title="{{item.name}}">{{item.name}}</span>
                    </ng-template>
                </ng-select>
                
                <ng-select #selectConnection placeholder="Select connection..." [(ngModel)]="query.connectionId"
                    (ngModelChange)="onSelectConnection(true)" [items]="connections | orderBy:'name'" [loading]="loadingConnections"
                    bindLabel="name" bindValue="id" [clearable]="false" [searchable]="false" [searchFn]="customSearchFn">
                    <ng-template ng-header-tmp>
                        <div class="search-wrapper">
                            <i class="ms-Icon ms-Icon--Search"></i>
                            <input type="text" (input)="selectConnection.filter($event.target.value)" autofocus
                                placeholder="Type to filter..." class="ms-TextField-field" />
                                
                                <button class="ms-Button ms-Button--command" (click)="refreshConnections()" title="Refresh">
                                    <span class="flex-container">
                                        <i class="ms-Icon ms-Icon--Refresh"></i>
                                    </span>
                                </button>
                        </div>
                    </ng-template>
                    <ng-template ng-label-tmp let-item="item">
                        <i class="icon-middle" *ngIf="item.type" [ngClass]="item.type"></i>
                        <span class="text" title="{{item.name}}">{{item.name}}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <i class="icon-middle" [ngClass]="item.type"></i>
                        <span title="{{item.name}}">{{item.name}}</span>
                    </ng-template>
                    <ng-template ng-footer-tmp>
                        <div class="btn-add-connection" (click)="selectConnection.close()">
                            <a href="{{host}}/#/{{query.workspaceId}}/connections/new" target="_blank">New</a>
                        </div>
                    </ng-template>
                    <ng-template ng-loading-tmp>
                        <div class="spinner-wrapper">
                            <div class="ms-Spinner" fabricSpinner></div>
                        </div>
                    </ng-template>
                </ng-select>



            </div>

            <div class="ms-Pivot">
                <ul class="ms-Pivot-links">
                    <li class="ms-Pivot-link is-selected" [ngClass]="{'is-selected': query.contentType == 0}"
                        title="Builder" tabindex="1" (click)="setView(0)">
                        Builder
                    </li>
                    <li class="ms-Pivot-link " [ngClass]="{'is-selected': query.contentType == 1}" title="SQL"
                        tabindex="1" (click)="setView(1)">
                        SQL
                    </li>
                </ul>
            </div>
        </div>

        <div class="modal-body">
            <ng-container [ngSwitch]="query.contentType">

                <ng-container *ngSwitchCase="0">
                    <skyvia-query-builder [(model)]="query.model" [connection]="query.connectionId" [workspaceId]="query.workspaceId" (onIsDirty)="validate()">
                    </skyvia-query-builder>
                </ng-container>

                <ng-container *ngSwitchCase="1">
                    <div aceEditor [mode]="'sql'" [theme]="'sqlserver'" [readOnly]="false" [options]="editorOptions"
                        [(text)]="query.sql" (textChanged)="onChangeSQL($event)" class="query-text">
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="button-bar bottom-right">

            <button class="ms-Button ms-Button--primary" (click)="run()" [disabled]="!canQuery"><span
                    class="ms-Button-label">Run</span> </button>

            <button class="ms-Button" [disabled]="!canSave" (click)="viewSave()" *ngIf="!menuSave()"><span
                    class="ms-Button-label">Save</span> </button>

            <span fabricContextualMenu *ngIf="menuSave()">
                <button class="ms-Button" [disabled]="!canSave"><span class="ms-Button-label">Save</span> </button>
                <ul class="ms-ContextualMenu is-hidden">
                    <li class="ms-ContextualMenu-item" (click)="save()">
                        <a class="ms-ContextualMenu-link" tabindex="1">Save</a>
                    </li>
                    <li class="ms-ContextualMenu-item" (click)="viewSave()">
                        <a class="ms-ContextualMenu-link" tabindex="1">Save As...</a>
                    </li>
                </ul>
            </span>

        </div>
        <!-- <div class="button-bar-right">
            <button class="ms-Button" (click)="viewParameters()"><span class="ms-Button-label">Parameters</span> </button>
        </div> -->

    </div>
    <form #parametersForm="ngForm" class="inline" *ngSwitchCase="'parameters'">
        <div class="modal-body">
            <div class="list-parameters">
                <div *ngFor="let parameter of parameters; let i = index">
                    <label class="ms-Label">{{parameter.name}}</label>
                    <div class="ms-Grid">
                        <div class="ms-Grid-row">
                            <div class="ms-Grid-col ms-u-sm4">
                                <div class="ms-Dropdown" tabindex="0" fabricDropdown>
                                    <i class="ms-Dropdown-caretDown ms-Icon ms-Icon--ChevronDown"></i>
                                    <select class="ms-Dropdown-select" [(ngModel)]="parameter.type"
                                        (ngModelChange)="changeParameterType(parameter)"
                                        [ngModelOptions]="{standalone: true}">
                                        <option *ngFor="let option of datatypes | keys" [value]="option.value">
                                            {{option.key}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="ms-Grid-col ms-u-sm8">
                                <value-selector name="{{'value_' + i}}" [(ngModel)]="parameter.value"
                                    [dataType]="parameter.type"></value-selector>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!parameters.length" class="text-center">
                <div class="gray">No Query Parameters</div>
            </div>
        </div>
        <div class="button-bar bottom-right">
            <button class="ms-Button ms-Button--primary" (click)="saveParameters(parametersForm)"
                [disabled]="!parameters.length">
                <span class="ms-Button-label">Apply</span>
            </button>
            <button class="ms-Button" (click)="cancel()"><span class="ms-Button-label">Cancel</span> </button>
        </div>

    </form>
    <form *ngSwitchCase="'save'" novalidate #saveForm="ngForm" (submit)="saveForm.form.valid && save()">

        <div class="modal-body">

            <div class="outer">
                <div class="middle">
                    <div class="inner">
                        <h2>Save Query</h2>
                        <div class="ms-TextField">
                            <label class="ms-Label">Query Name</label>
                            <input type="text" class="ms-TextField-field" [(ngModel)]="query.name" name="name" required
                                #name="ngModel"  placeholder="Type a name..."/>
                            <small
                                *ngIf="name.hasError('required') && saveForm.submitted"
                                class="ms-TextField-errorMessage">
                                Name is required.
                            </small>
                        </div>

                        <div class="ms-TextField ms-TextField--multiline">
                            <label class="ms-Label">Description</label>
                            <textarea class="ms-TextField-field" [(ngModel)]="query.description"
                                [ngModelOptions]="{standalone: true}" placeholder="Describe your Query..."
                                rows="3" maxlength="4000" style="resize: none;"></textarea>
                        </div>

                        <div *ngIf="query.id">
                            <div class="ms-CheckBox" fabricCheckBox>
                                <input class="ms-CheckBox-input" tabindex="-1" type="checkbox" id="saveAsCopy"
                                    [(ngModel)]="saveAsCopy" [ngModelOptions]="{standalone: true}"
                                    [disabled]="!!query.sharingType">
                                <label role="checkbox" class="ms-CheckBox-field" for="saveAsCopy"
                                    title="Save as a New Query" [ngClass]="{'is-disabled':!!query.sharingType}">
                                    <span class="ms-Label">Save as a New Query</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>
        <div class="button-bar bottom-right">
            <button class="ms-Button ms-Button--primary" type="submit"><span class="ms-Button-label">Save</span>
            </button>
            <button class="ms-Button" type="button" (click)="cancel()"><span class="ms-Button-label">Cancel</span> </button>
        </div>
    </form>
</div>