import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { QueryBuilderService } from 'app/query/query-builder/query-builder.service';
import { QueryService } from 'app/query/query.service';
import { ConditionService } from 'app/query/query-builder/condition.service';
import { Utils } from 'app/services/util';
import { Toast, MessageBarType } from 'app/_fabric/Toast';


@Component({
    selector: 'condition-list',
    templateUrl: './condition-list.component.html',
    styleUrls: ['./condition-list.component.scss']
})
export class ConditionListComponent implements OnChanges {

    @Input() selectedObject;
    @Input() sources;
    @Input() connection;
    @Input() workspaceId;

    @Output() isDirty: EventEmitter<any> = new EventEmitter();

    operators;
    toast;

    loading;
    loadingMore;

    valuesList = [];

    constructor(
        private queryBuilderSvc: QueryBuilderService,
        private queryService: QueryService,
        private conditionSvc: ConditionService,
        private utils: Utils) {

        this.operators = this.conditionSvc.getListOperators();
        this.toast = new Toast();
    }

    ngOnChanges(changes) {

        if (changes.selectedObject.currentValue !== changes.selectedObject.previousValue) {
            this.selectedObjectChanged(changes.selectedObject.currentValue);
        }
    }

    selectedObjectChanged(value) {

        if (!this.operators.some(f => f.id == value.operator)) {
            value.operator = this.operators[0].id;
        }

        if (!value.$$listItems) {
            value.$$listItems = [];
        }

        this.listItemsToObjValue();

        this.initValuesList();
    }


    listItemsToObjValue() {

        this.selectedObject.arguments = [];
        this.selectedObject.$$listItems.forEach(el => this.selectedObject.arguments.push(this.queryBuilderSvc.createExpressionConstant(el)));

        this.isDirty.emit();
    }

    initValuesList() {
        this.loading = true;

        let obj = this.selectedObject;
        let model = this.getModel(obj);
        this.valuesList = [];

        this.queryService.queryColumn(this.connection, this.workspaceId, model)
            .subscribe((response: any) => {
                obj.$$nextPageToken = response.nextPageToken;

                const values = response.result.map(value => this.objectValues(value).join());
                this.valuesList = [...values];

                this.loading = false;
            }, (response) => {
                this.loading = false;

                const message = response?.error?.message ?? response?.message;
                this.toast.showToast(MessageBarType.error, message);
            });
    }

    loadMore() {
        this.loading = true;

        let obj = this.selectedObject;
        if (obj.$$nextPageToken) {
            this.queryService.queryMore(this.connection, this.workspaceId, obj.$$nextPageToken)
                .subscribe((response: any) => {

                    let moreData = response.result.map(value => this.objectValues(value).join());
                    obj.$$nextPageToken = response.nextPageToken;

                    if (moreData && moreData.length) {
                        this.valuesList = [... this.valuesList, ...moreData];
                        // moreData.forEach(el => {
                        //     this.valuesList.push(el);
                        // });
                    }
                    this.loading = false;
                }, (response) => {
                    this.loading = false;

                    const message = response?.error?.message ?? response?.message;
                    this.toast.showToast(MessageBarType.error, message);
                });
        } else {
            this.loading = false;
        }
    }

    private getModel(value) {

        const model = {
            columnName: value.expression.column,
            tableName: value.expression.source
        };

        const tableRefs = this.sources.map(m => m.tableRef).filter(f => f);
        if (tableRefs.some((s) => { return s.relation == model.tableName || s.label == model.tableName })) {
            model.tableName = tableRefs.filter((s) => { return s.relation == model.tableName || s.label == model.tableName })[0].relatedTable;
        }

        return model;
    }

    private objectValues = (object) => {
        return Object.keys(object).map(k => {
            return object[k];
        });
    }


    // private getModel(value) {

    //     const model = {
    //         columnName: value.expression.column,
    //         tableName: value.expression.source
    //     };

    //     if (this.sources.some((s) => { return s.relation == model.tableName || s.label == model.tableName })) {
    //         model.tableName = this.sources.filter((s) => { return s.relation == model.tableName || s.label == model.tableName })[0].relatedTable;
    //     }

    //     return model;
    // }

    // private fillListItems(obj) {

    //     let model = this.getModel(obj);

    //     if (!(this.utils.isUndefined(model) || model === null)) {

    //         obj.$$loadItems = true;

    //         this.querySvc.queryColumn(this.connection, model)
    //             .subscribe((response: any) => {
    //                 obj.$$nextPageToken = response.nextPageToken;

    //                 response.result.forEach(el => {
    //                     if (!(this.utils.isUndefined(el[model.columnName]) || el[model.columnName] === null)) {
    //                         var name = this.trimRight(el[model.columnName]);
    //                         var index = obj.$$listItems.filter(f => { return f.name == name }).length;
    //                         if (index <= 0) {
    //                             obj.$$listItems.push({ name: name, selected: false });
    //                         }
    //                     }
    //                 });

    //             },
    //             () => {
    //                     obj.$$loadItems = false;
    //                 });
    //     }
    // }

    // loadMore() {

    //     let obj = this.selectedObject;
    //     let model = this.getModel(obj);

    //     obj.$$loadItems = true;

    //     this.querySvc.queryMore(this.connection, obj.$$nextPageToken)
    //         .subscribe((response: any) => {

    //             let moreData = response.result;
    //             obj.$$nextPageToken = response.nextPageToken;

    //             if (moreData && moreData.length) {
    //                 moreData.forEach(el => {
    //                     if (!(this.utils.isUndefined(el[model.columnName]) || el[model.columnName] === null)) {
    //                         obj.$$listItems = obj.$$listItems.concat([{ name: el[model.columnName], selected: false }]);
    //                     }
    //                 });
    //             }

    //         },
    //             () => {
    //                 obj.$$loadItems = false;
    //             });
    // }





}
