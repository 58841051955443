import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';

@Component({
    templateUrl: 'refresh.component.html',
    styleUrls: ['refresh.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefreshComponent implements OnInit, OnDestroy {

    intervalId;
    sheets = [];

    constructor(private cdr: ChangeDetectorRef) { }

    ngOnInit() {

        // Office.onReady()
        // .then(()=> {
        //     Office.context.ui.addHandlerAsync(
        //         Office.EventType.DialogParentMessageReceived,
        //         this.onMessageFromParent.bind(this));
        // });

        localStorage.removeItem('refresh');

        this.run()
            .then(_ => {
                this.intervalId = setInterval(() => {
                    const refreshData = JSON.parse(localStorage.getItem('refresh'));
                    if (refreshData) {
                        this.sheets = refreshData;
                        this.cdr.detectChanges();
                    }

                }, 1000);
            });
    }

    ngOnDestroy() {
        if (this.intervalId) {
          clearInterval(this.intervalId);
        }
      }

    run(): Promise<void> {

        return new Promise<void>(resolve=>{
            Office.context.ui.messageParent('');
            return resolve();
        });

    }

}
