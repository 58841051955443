import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';

@Injectable()
export class Utils {

    options = {
        decimalSeparator: (1.1).toLocaleString().substring(1, 2),
        dateFormat: 'MM/DD/YYYY',
        dateTimeFormat: 'MM/DD/YYYY HH:mm',
        dateTimeSecondsFormat: 'MM/DD/YYYY HH:mm:ss'
    };

    isArray = Array.isArray;

    isNull(value) { return value === null; }

    isUndefined(value) { return typeof value === 'undefined'; }

    isDefined(value) { return typeof value !== 'undefined'; }

    isDate(value) {
        return Object.prototype.toString.call(value) === '[object Date]';
    }

    isString(value) {
        return typeof value === 'string';
    }

    isNumber(value) {
       return typeof value === 'number';
    }

    isFunction(value) { return typeof value === 'function'; }

    isRegExp(value) {
        return Object.prototype.toString.call(value) === '[object RegExp]';
    }

    isObject(value) {
        return value !== null && typeof value === 'object';
    }


    isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    baseExtend(dst, objs, deep) {

        for (let i = 0, ii = objs.length; i < ii; ++i) {
            const obj = objs[i];
            if (!this.isObject(obj) && !this.isFunction(obj)) { continue; }
            const keys = Object.keys(obj);
            for (let j = 0, jj = keys.length; j < jj; j++) {
                const key = keys[j];
                const src = obj[key];

                if (deep && this.isObject(src)) {
                    if (this.isDate(src)) {
                        dst[key] = new Date(src.valueOf());
                    } else if (this.isRegExp(src)) {
                        dst[key] = new RegExp(src);
                    } else {
                        if (!this.isObject(dst[key])) { dst[key] = this.isArray(src) ? [] : {}; }
                        this.baseExtend(dst[key], [src], true);
                    }
                } else {
                    dst[key] = src;
                }
            }
        }

        return dst;
    }

    extend(dst: any, ...arg: any[]) {
        return this.baseExtend(dst, arg, false);
    }

    merge(dst: any, ...arg: any[]) {
        return this.baseExtend(dst, arg, true);
    }

    equals(x, y) {
        if (x === y) {
            return true; // if both x and y are null or undefined and exactly the same
        } else if (!(x instanceof Object) || !(y instanceof Object)) {
            return false; // if they are not strictly equal, they both need to be Objects
        } else if (x.constructor !== y.constructor) {
            // they must have the exact same prototype chain, the closest we can do is
            // test their constructor.
            return false;
        } else {
            for (const p in x) {
                if (!x.hasOwnProperty(p)) {
                    continue; // other properties were tested using x.constructor === y.constructor
                }
                if (!y.hasOwnProperty(p)) {
                    return false; // allows to compare x[ p ] and y[ p ] when set to undefined
                }
                if (x[p] === y[p]) {
                    continue; // if they have the same strict value or identity then they are equal
                }
                if (typeof (x[p]) !== 'object') {
                    return false; // Numbers, Strings, Functions, Booleans must be strictly equal
                }
                if (!this.equals(x[p], y[p])) {
                    return false;
                }
            }
            for (const p in y) {
                if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
                    return false;
                }
            }
            return true;
        }
    }

    uncapitalize(text) {
        if (!text || typeof text !== 'string') {
            return '';
        }

        return text.charAt(0).toLowerCase() + text.substr(1);
    }

    isValidDate(value, dateOnly) {

        if (!value) {
            return false;
        }

        if (this.isString(value)) {
            let m;
            if (dateOnly) {
                m = dayjs(value, this.options.dateFormat, true);
            } else {
                m = dayjs(value, this.options.dateTimeFormat, true);
                if (!m.isValid()) {
                    m = dayjs(value, this.options.dateTimeSecondsFormat, true);
                }
            }

            if (m.isValid()) {
                return true;
            }
        } else if (this.isDate(value)) {
            return true;
        }

        return false;
    }

    convertUTCDateToLocalDate(value: Date): Date {
        return new Date(Date.UTC(
            value.getFullYear(),
            value.getMonth(),
            value.getDate(),
            value.getHours(),
            value.getMinutes(),
            value.getSeconds())
        );
    }

    convertLocalDatetoUTCDate(value: Date): Date {
        return new Date(
            value.getUTCFullYear(),
            value.getUTCMonth(),
            value.getUTCDate(),
            value.getUTCHours(),
            value.getUTCMinutes(),
            value.getUTCSeconds()
        );
    }

    createDate( value: Date, hours: number, minutes: number, seconds: number): Date {
        return new Date(
          value.getFullYear(),
          value.getMonth(),
          value.getDate(),
          hours,
          minutes,
          seconds
        );
      }

    getValidDate(value): Date {
        return this.isDate(value) ? value : new Date();
    }

    copy(source, destination?) {

        if (!destination) {
            destination = source;
            if (source) {
                if (this.isArray(source)) {
                    destination = this.copy(source, []);
                } else if (this.isDate(source)) {
                    destination = new Date(source.getTime());
                } else if (source.toString() === '[object RegExp]') {
                    destination = new RegExp(source.source);
                } else if (this.isObject(source)) {
                    destination = this.copy(source, {});
                }
            }
        } else {
            if (source === destination) { throw Error('Can\'t copy equivalent objects or arrays'); }
            if (this.isArray(source)) {
                destination.length = 0;
                for (let i = 0; i < source.length; i++) {
                    destination.push(this.copy(source[i]));
                }
            } else {

                for (const key in source) {

                    if (key.substring(0, 2) === '$$') {
                        destination[key] = source[key];
                    } else {
                        destination[key] = this.copy(source[key]);
                    }
                }
            }
        }
        return destination;
    }


    jsonDateParser(key, value) {

        const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.{0,1}\d*))(?:Z|(\+|-)([\d|:]*))?$/;
        const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

        const jsonDateParser = (_: string, value: any) => {

            let parsedValue = value;
            if (typeof value === 'string') {
                let a = reISO.exec(value);
                if (a) {
                    parsedValue = new Date(value);
                } else {
                    a = reMsAjax.exec(value);
                    if (a) {
                        var b = a[1].split(/[-+,.]/);
                        parsedValue = new Date(b[0] ? +b[0] : 0 - +b[1]);
                    }
                }
            }

            return parsedValue;
        };

        return jsonDateParser(key, value);
    }

    toInt(str) {
        return parseInt(str, 10);
    }

    isArrayLike(obj) {
        if (obj == null) {
            return false;
        }

        // Support: iOS 8.2 (not reproducible in simulator)
        // "length" in obj used to prevent JIT error (gh-11508)
        let length = "length" in Object(obj) && obj.length;

        if (obj.nodeType === 1 && length) {
            return true;
        }

        return this.isString(obj) || this.isArray(obj) || length === 0 ||
            typeof length === 'number' && length > 0 && (length - 1) in obj;
    }
}
