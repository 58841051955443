import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatatypesService } from '../../../services/datatypes.service';
import { ConditionService } from 'app/query/query-builder/condition.service';
import { QueryBuilderService } from 'app/query/query-builder/query-builder.service';
import { Utils } from 'app/services/util';
import { QueryService } from 'app/query/query.service';
import { Toast, MessageBarType } from '../../../_fabric/Toast';

@Component({
    selector: 'filter-condition',
    templateUrl: './filter-condition.component.html',
    styleUrls: ['./filter-condition.component.scss'],
})
export class FilterConditionComponent implements OnInit {

    @Output() addSources: EventEmitter<any> = new EventEmitter<any>();
    @Output() recountSource: EventEmitter<any> = new EventEmitter<any>();
    @Output() onIsDirty: EventEmitter<any> = new EventEmitter();

    @Input() index;
    @Input() tableRefs;
    @Input() item;
    @Input() parentItem;
    @Input() parentIndex;
    @Input() showErrors;
    @Input() showVariables;
    @Input() showLastRunVariable;

    @Input() connection;
    @Input() workspaceId;

    tableRef;

    editor;
    editorsList;

    toast;

    constructor(
        private conditionSvc: ConditionService,
        private datatypesSvc: DatatypesService) {
        this.toast = new Toast();
    }

    private findByName(items, name) {
        for (var i = 0; i < items.length; i++) {
            if (items[i].name == name) {
                return items[i];
            }
        }
        return null;
    }

    ngOnInit() {

        new Promise<void>((resolve, _) => {
            if (this.item.expression.source) {
                this.tableRef = this.findByName(this.tableRefs, this.item.expression.source);
            } else {
                this.tableRef = this.tableRefs[0];
                this.item.expression.source = this.tableRef.name;
            }
            resolve();
        }).then(() => {

            this.loadingColumns().then(_ => {
                if (this.item.expression.column) {
                    this.initColumn();
                }
            });
        });

    }

    remove() {
        if (this.parentItem) {
            var index = this.parentItem.items.indexOf(this.item);
            this.parentItem.items.splice(index, 1);

            this.recountSource.emit();
            this.isDirty();
        }
    }

    changeTableRef() {

        this.item.expression.source = this.tableRef.name;

        this.loadingColumns()
            .then(() => {
                this.item.expression.column = this.tableRef.columns[0].name;
                this.initColumn();

            });

        if (this.tableRef) {
            this.addSources.emit(this.tableRef.tableRef)
        }
    }

    loadingColumns() {

        return new Promise<void>((resolve, _) => {
            if (!this.tableRef.columns) {
                this.tableRef
                    .get()
                    .then(result => {
                        this.tableRef.columns = result.columns;
                        resolve();
                    });
            } else {
                resolve();
            }
        });
    }

    changeColumn() {

        this.item.$$values = [];
        this.switchEditor(null);
        setTimeout(() => { this.initColumn(true) });
    }

    initColumn(force?) {

        const column = this.tableRef.columns.find(f => f.name == this.item.expression.column);
        if (column) {
            this.item.expression.dataType = column.dataType;
        }

        this.editorsList = ['Value'];

        const value = this.item;
        const dataType = this.datatypesSvc.getUserFriendlyTypeName(value.expression.dataType);
        switch (dataType) {
            case 'Date':
            case 'DateTime':
            case 'DateTimeOffset':
                this.editorsList.push('Relative');
                break;
            case 'Text':
                this.editorsList.push('List');
        }

        if (force) {
            this.switchEditor('Value');
        } else {
            if (this.conditionSvc.getValueOperators(dataType).some(f => f.id === value.operator)) {

                if (value.arguments?.length && value.arguments[0].type === 'Constant')
                    this.switchEditor('Value');
                else if (value.arguments?.length && value.arguments[0].type === 'Parameter')
                    this.switchEditor('Parameter');
                else
                    this.switchEditor('Value');
            }
            else if (this.conditionSvc.getListOperators().some(f => f.id === value.operator))
                this.switchEditor('List');
            else if (this.conditionSvc.getRelativeOperators().some(f => f.id === value.operator))
                this.switchEditor('Relative');
            else
                this.switchEditor('Value');
        }

    }

    switchEditor(value) {
        this.editor = value;
    }

    isDirty() {
        this.onIsDirty.emit();
    }

}
